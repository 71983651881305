import { 
  useReactTable, 
  createColumnHelper, 
  getCoreRowModel, 
  getSortedRowModel, 
  flexRender 
} from '@tanstack/react-table'
import React, { useState } from 'react'

const columnHelper = createColumnHelper()

const columns = [
  columnHelper.accessor('title', {
    header: 'Title',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('userStockSold', {
    header: 'Sales',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('userStock', {
    header: 'Stock',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('userStockOnHold', {
    header: 'Reserved',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('waitingListUsers', {
    header: 'Users',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('waitingListTickets', {
    header: 'Tickets',
    cell: info => info.getValue(),
  }),
]

export function TicketsTable({ data }) {
  const [sorting, setSorting] = useState([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const isSorted = header.column.getIsSorted();
              return (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                >
                  <div className="flex items-center">
                    <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>
                    <span className="ml-2">
                      {isSorted === false ? (
                        <span className="text-gray-400">↕</span>
                      ) : isSorted === 'asc' ? (
                        <span className="text-blue-600">↑</span>
                      ) : (
                        <span className="text-blue-600">↓</span>
                      )}
                    </span>
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {table.getRowModel().rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map(cell => (
              <td
                key={cell.id}
                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
