import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import QRCode from 'qrcode.react'
import Form from './Form/Form'
import Section from './Lib/Section'
import flatten from './Lib/flatten'
import dayjs from 'dayjs'
import colorFromString from './Lib/colorFromString'
import { RefetchSumsubImage, refundRedsysMarketplacePurchase, RefundRedsysPurchase, TransferTicketManager } from '../externalApi/gql.services'
import Button from './Button'
import ModalCaptureImage from './ModalCaptureImage'
import { Modal, CloseButton } from './Modal'
import { useSession } from '../contexts/AuthContext'
import uploadUserImage from './Lib/uploadUserImage'
import useOrganizationInfo from '../hooks/useOrganizationInfo'

const slugify = text => {
  return text
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-')
}

// NOTE: THIS SHOULD COME FROM THE EVENT TZ
const DEFAULT_TIMEZONE = 'Europe/Madrid'

const EditTickets = (updateEvents) => {
  const history = useHistory()
  const { id: urlId, event } = useParams()
  const { getOrganization, getEvent } = useOrganizationInfo(event)
  const modelName = 'Ticket'
  const [id, setId] = useState(urlId)
  const [data, setData] = useState(false)
  const [originalData, setOriginalData] = useState(false)
  const [name, setName] = useState(modelName)
  const [title, setTitle] = useState(name)
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState({})
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [notUniqueId, setNotUniqueId] = useState(false)
  const [ticketFields, setTicketFields] = useState([])
  const [loadingFields, setLoadingFields] = useState(false)
  const [showHistoryViews, setShowHistoryViews] = useState(false)
  const [walletTypes, setWalletTypes] = useState([])
  const [wristBandCodeError, setWristBandCodeError] = useState(false)
  const [walletData, setWalletData] = useState()
  const [showCamera, setshowCamera] = useState(false)
  const [newSelfie, setNewSelfie] = useState(null)
  const [firstEntrance, setFirstEntrance] = useState()
  const [entrances, setEntrances] = useState(false)
  const [hasMoreEntrances, setHasMoreEntrances] = useState(false)
  const [consumables, setConsumables] = useState()
  const [purchases, setPurchases] = useState()
  const [role, setRole] = useState(false)
  const [readMode, setReadMode] = useState(false)
  const token = useSession()
  const [showFullscreenImage, setShowFullscreenImage] = useState(false)
  const [loading, setLoading] = useState(false)
  const [transferEmail, setTransferEmail] = useState()
  const [selectedRefundPurchase, setSelectedRefundPurchase] = useState(false)
  const [selectedRefundPurchaseDetails, setSelectedRefundPurchaseDetails] = useState(false)
  const [refundPurchaseForm, setRefundPurchaseForm] = useState({ reason: '', confirm: '', amount: '0', refundTotal: false })
  const [refundPurchaseResponse, setRefundPurchaseResponse] = useState({ ok: false, message: "" })

  const [filters] = useState({
    organization: getOrganization(),
    event: getEvent(),
  })

  const updateStored = async (key, value, errs = []) => {
    if (key === 'event' && role !== "read") {
      setLoadingFields(true)
      if (value && value.toString() !== "-1") {
        await fetch(`${process.env.REACT_APP_API_URL}model-edit-ticket`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            modelName,
            filters: {
              organization: Cookies.get('organization'),
              event: Cookies.get('event'),
              findModelsBySelectedEvent: {
                organization: Cookies.get('organization'),
                event: value,
              }
            },
          }),
        })
          .then(response => response
            .json()
            .then(data => ({ status: response.status, body: data })))
          .then(response => {
            setLoadingFields(false)
            if (response.status === 200) {
              data.find = {
                ...data.find,
                TicketType: response.body.TicketType
              }
            } else {
              console.log('Error', response.status, response.body.error)
              if (response.status === 403) {
                Cookies.remove('user')
                history.push('/login')
              } else if (response.status === 401) {
                window.location.replace(`/admin/no-access?url=/admin/list/${modelName}`);
              }
            }
          })
      }
    }

    if (key === 'ticketType') {
      if (value) {
        setLoadingFields(true)
        const bodyData = {
          modelName,
          getTicketTypeFields: value,
          filters,
        }

        fetch(`${process.env.REACT_APP_API_URL}model-edit-ticket`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyData),
        })
          .then(response => response
            .json()
            .then(data => ({ status: response.status, body: data })))
          .then(response => {
            setLoadingFields(false)
            if (response.status === 200) {
              setTicketFields(response.body.ticketTypeFields)
            }
          })
      } else {
        setTicketFields([])
      }
    }

    setNotUniqueId()

    if (!data.stored) {
      data.stored = {}
    }

    if (typeof data.stored.modelName === 'undefined') {
      data.stored.modelName = name
    }

    data.stored[key] = key === 'email' ? value.trim() : value
    errors[key] = errs
    setData({ ...data })
    setErrors({ ...errors })
  }

  useEffect(() => {
    setData(false)
    setOriginalData(false)
    setName(modelName)
    const bodyData = {
      modelName,
      filters,
    }

    if (id) {
      bodyData._id = id
    }

    fetch(`${process.env.REACT_APP_API_URL}model-edit-ticket`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setRole(response.body.role)
          setTitle(`${id ? 'Edit' : 'Create'} ${response.body.modelConfig.title}`)
          setWalletTypes([...response.body.modelConfig.wallet])
          const parsedData = response.body
          if (parsedData.stored && parsedData.stored.fields && parsedData.stored.fields.birthDate) {
            parsedData.stored.fields.birthDate = dayjs(parsedData.stored.fields.birthDate).format('YYYY-MM-DD')
          }
          if (parsedData.stored && parsedData.stored.info && parsedData.stored.info.birthDate) {
            parsedData.stored.info.birthDate = dayjs(parsedData.stored.info.birthDate).format('YYYY-MM-DD')
          }
          setData(parsedData)
          setReadMode(response.body.role === 'read' || !!parsedData.stored.deleted)
          setWalletData(parsedData.stored.wallet)
          const cloneResponseStored = JSON.parse(JSON.stringify(response.body.stored))
          setOriginalData(cloneResponseStored)
          if (response.body.ticketTypeFields && response.body.ticketTypeFields.length > 0) {
            setTicketFields(response.body.ticketTypeFields)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            window.location.replace(`/admin/no-access?url=/admin/list/${modelName}`);
          }
        }
      })
  }, [modelName, id])

  const orderModels = function (data) {
    Object.keys(data).forEach(key => {
      const staticElement = data[key]
      if (Array.isArray(staticElement)) {
        staticElement.forEach((jsonElement, key2) => {
          if (jsonElement['items']) {
            data[key][key2]['items'] = jsonElement['items'].sort((a, b) => a.position - b.position)
          }
        });
      }
    })
  }

  const checkIfTicketIdIsUnique = async (data, ticketModel) => {
    if (!ticketModel) return false
    const bodyData = {
      ...data,
      filters,
    }

    let isUnique = false
    await fetch(`${process.env.REACT_APP_API_URL}ticket-unique`, {
      body: JSON.stringify(bodyData),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.body.unique) {
          isUnique = true
        }
      })
      .catch((err) => console.log(err))
    return isUnique
  }

  const save = async (type = 'default', callback = false) => {
    setWristBandCodeError(false)
    const errs = flatten(errors)
    if (Object.keys(flatten(errs)).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setSaving(true)
      orderModels(data.stored)
      const ticketModel = (modelName === 'Ticket')
      const uniqueTicketId = await checkIfTicketIdIsUnique(data.stored, ticketModel)
      if (!ticketModel || uniqueTicketId) {
        const bodyData = {
          ...data.stored,
          modelName,
          originalData,
          filters,
        }

        fetch(`${process.env.REACT_APP_API_URL}model-update-ticket`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyData),
        })
          .then(response => response
            .json()
            .then(data => ({ status: response.status, body: data })))
          .then(response => {
            setSaving(false)
            const cloneResponseStored = JSON.parse(JSON.stringify(data.stored))
            setOriginalData(cloneResponseStored)
            if (callback) callback(response)
            if (response.status === 200) {
              const eventSelected = data.find.Event.find(findEvent => response.body.event === findEvent._id)
              const eventSlug = slugify(eventSelected.title)
              setReadMode(response.body.role === 'read' || !!response.body.deleted)

              if (modelName === 'Event') {
                updateEvents.setUpdateEvents(true)
              }
              if (type === 'default') {
                if (typeof id === 'undefined') {
                  setId(response.body._id)
                  history.push(`/admin/${eventSlug}/edit/Ticket/${response.body._id}`)
                } else if (event !== eventSlug) {
                  history.push(`/admin/${eventSlug}/edit/Ticket/${response.body._id}`)
                  if (!showCamera) {
                    history.go(0)
                  }
                }
                setSaved(true)
                setTimeout(() => setSaved(false), 3000)
                return
              } else if (type === 'save-and-another') {
                setId(null)
                history.push(`/admin/${eventSlug}/edit/Ticket/`)
                history.go(0)
                return
              } else if (type === 'save-and-close') {
                history.push(`/admin/${eventSlug}/list/Ticket`)
              } else if (type === 'save-and-reload') {
                history.go(0)
              }
            } else {
              console.log('Error', response.status, response.body.error)
              if (response.status === 400 && response.body.error === 'ERROR_WRISTBAND_CODE_ALREADY_EXISTS') {
                setWristBandCodeError(true)
              }
              if (response.status === 403) {
                Cookies.remove('user')
                history.push('/login')
              } else if (response.status === 401) {
                history.push(`/admin/no-access?url=${window.location.pathname}`)
              }
            }
          })
          .catch(() => setSaving(false))
      } else {
        setSaving(false)
        if (!uniqueTicketId) {
          setNotUniqueId(true)
        }
      }
    }
  }

  const onChangeField = (field, value) => {
    const rawFields = data.stored.fields || {}
    rawFields[field] = value
    updateStored('fields', rawFields)
  }

  const onChangeWallet = (type, value) => {
    let rawWallet = data.stored.wallet
    if (!rawWallet) {
      rawWallet = {}
    }
    if (!value && value !== 0) {
      rawWallet[type] = null
    } else {
      rawWallet[type] = Number(value)
    }
    updateStored('wallet', rawWallet)
    setWalletData(rawWallet)
  }

  const onChangeInfoKYC = (field, value) => {
    const rawFields = data.stored.info || {}
    rawFields[field] = value
    updateStored('info', rawFields)
  }

  const updateKYC = () => {
    if (readMode) return false
    const finalValue = data?.stored?.validation?.sumsub?.valid ? false : true
    const rawValidation = data.stored.validation || {}
    if (!rawValidation?.sumsub) {
      rawValidation.sumsub = {}
    }
    if (!rawValidation?.data) {
      rawValidation.data = {}
    }
    rawValidation.sumsub.valid = finalValue
    rawValidation.data.valid = finalValue

    updateStored('validation', rawValidation)
  }

  const removeKYC = () => {
    const rawValidation = data?.stored?.validation
    if (window.confirm(`Are you sure you want to remove this KYC?  \r\n${JSON.stringify(rawValidation[data.stored.kycType], undefined, 2)}`)) {
      delete rawValidation[data.stored.kycType]
      updateStored('info', {})
      updateStored('validation', rawValidation)
      setTimeout(() => {
        save('save-and-reload')
      }, 500);
    }
  }

  const removeUserData = () => {
    if (window.confirm(`Are you sure you want this ticket from this user?  \r\n${JSON.stringify({
      userId: data.stored.userId,
      deviceId: data.stored.deviceId,
    }, undefined, 2)}`)) {
      updateStored('userId', null)
      updateStored('deviceId', null)
    }
  }

  const duplicate = id => {
    if (window.confirm('Duplicate?')) {
      fetch(`${process.env.REACT_APP_API_URL}model-duplicate`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          modelName,
          organization: getOrganization(),
          event: getEvent(),
          _id: id,
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            history.push(`/admin/${event}/edit/Ticket/${response.body.duplicatedId}`)
            setId(response.body.duplicatedId)
          } else if (response.status === 400) {
            alert('Error ' + response.body.error)
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('user')
              history.push('/login')
            } else if (response.status === 401) {
              history.push(`/admin/no-access?url=${window.location.pathname}`)
            }
          }
        })
    }
  }

  const remove = id => {
    if (window.confirm(data.stored.deleted ? 'Recover ticket?' : 'Delete ticket?')) {
      updateStored('deleted', !data.stored.deleted)
      setTimeout(() => {
        save()
      }, 200);
    }
  }

  const refreshSelfie = async () => {
    if (window.confirm('Refresh selfie?')) {
      try {
        const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
          method: 'POST',
          headers: {
            Authorization: `${token}`,
            'AccessTicket-App': 'manager',
          },
          body: JSON.stringify({
            query: RefetchSumsubImage,
            variables: {
              ticketId: data.stored.publicId,
            },
          }),
        })
        const response = await res.json()

        if (response && response.errors && response.errors.length) {
          alert('Error ' + response.errors)
        } else {
          alert('Image refreshed')
          window.location.reload()
        }
      } catch (err) {
        console.log('err', err)
        setErrors(['API ERROR'])
      }
    }
  }

  const deleteSelfie = () => {
    if (window.confirm('Delete selfie?')) {
      const rawValidation = data.stored.validation
      delete rawValidation.selfie
      updateStored('validation', rawValidation)
      setNewSelfie(null)
      setTimeout(() => {
        updateStored('validationImage', null)
      }, 500);
    }
  }

  const defaultUserManagerPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))

  const [visibleSections, setVisibleSections] = useState(
    defaultUserManagerPreferences && defaultUserManagerPreferences.ticketVisibleSections
    || [
      'fields',
      'kyc',
      'info',
      'originalTicket',
      'wallet',
      'others',
    ])

  useEffect(() => {
    let userManagerPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))
    if (!userManagerPreferences) {
      userManagerPreferences = {}
    }

    userManagerPreferences.ticketVisibleSections = [...visibleSections]

    Cookies.set('userManagerPreferences', JSON.stringify(userManagerPreferences), { expires: 365 })
  }, [visibleSections])

  const handleVisibleSections = (section) => {
    let rawSections = [...visibleSections]
    if (rawSections.includes(section)) {
      rawSections = rawSections.filter(col => col !== section)
    } else {
      rawSections.push(section)
    }
    setVisibleSections([...rawSections])
  }

  const handleUploadFile = async (e) => {
    const currentFiles = e.target.files
    e.preventDefault()

    if (data?.stored?.hasBeenUsed && data?.hasEntrances) {
      alert('This ticket has already been used')
      return
    }

    const result = await uploadUserImage({
      ticketId: data.stored.publicId,
      token,
      image: currentFiles[0],
    })

    if (!result.valid) {
      alert('Error uploading image: ' + result.error)
    } else {
      alert('Image uploaded')
      window.location.reload()
    }
  }

  const handleOpenCamera = () => {
    if (!window.confirm(`⚠️ When taking the photo, please remember that the user must sign the consent form.`)) return
    if (readMode) return
    setshowCamera(true)
    save()
  }

  const handleGetEntranceTime = () => {
    fetch(`${process.env.REACT_APP_API_URL}get-entrance-time`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ticketId: id, }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setFirstEntrance(response.body.result[0])
      }).catch(error => console.log('error', error))
  }

  const loadEntrances = () => {
    const entrancesLimit = 20
    fetch(`${process.env.REACT_APP_API_URL}get-ticket-entrances`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ticketId: id, limit: entrancesLimit, page: (entrances.length / entrancesLimit) || 0 }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.body.result) {
          if (Array.isArray(entrances)) {
            setEntrances([...entrances, ...response.body.result])
          } else {
            setEntrances([...response.body.result])
          }
          setHasMoreEntrances(response.body.result.length === entrancesLimit)
        } else {
          setEntrances([])
        }
      }).catch(error => console.log('error', error))
  }

  const loadConsumables = () => {
    fetch(`${process.env.REACT_APP_API_URL}get-ticket-consumables`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ticketId: id }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.body.result) {
          setConsumables(response.body.result)
        } else {
          setConsumables([])
        }
      }).catch(error => console.log('error', error))
  }

  const loadPurchases = () => {
    fetch(`${process.env.REACT_APP_API_URL}get-ticket-purchases`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ticketId: id }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.body.result) {
          setPurchases(response.body.result)
        } else {
          setPurchases([])
        }
      }).catch(error => console.log('error', error))
  }

  const switchManualValidation = () => {
    const rawValidation = data.stored.validation || {}
    if (!rawValidation.manual) {
      rawValidation.manual = { valid: false }
    }
    rawValidation.manual.valid = !rawValidation.manual.valid
    updateStored('validation', rawValidation)
  }

  const handlePrintAccreditation = (e) => {
    setLoading(true)
    e.preventDefault()
    fetch(`${process.env.REACT_APP_API_URL}generate-accreditation`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        modelName,
        organization: getOrganization(),
        event: getEvent(),
        _id: data.stored._id,
      }),
    })
      .then(response => {
        setLoading(false)
        switch (response.status) {
          case 200:
            response.blob().then((blob) => {
              const printWindow = window.open('', '_blank');
              printWindow.document.write('<html><head><title>Print</title></head><body>');
              printWindow.document.write('<embed width="100%" height="100%" src="' + URL.createObjectURL(blob) + '" type="application/pdf">');
              printWindow.document.write('</body></html>');
              printWindow.document.close();
              printWindow.print();
            })
            break;

          case 400:
            response.json()
              .then((response) => {
                if (response?.error === 'INVALID_IMAGE') {
                  alert('Invalid image, try to upload a new one.')
                } else if (response?.error === 'NO_INFO') {
                  alert(
                    'Ticket has no enough info to generate accreditation. Please check "Info" section and fill first name and last name.'
                  )
                }
              })
            break;
        }
      }).catch(error => console.log('error', error))
  }

  const transferTicket = async () => {
    if (!transferEmail || !transferEmail.includes('@') || !transferEmail.includes('.')) {
      alert('Invalid email')
      return
    }

    if (window.confirm(`Are you sure to transfer this ticket to ${transferEmail} ?`)) {
      try {
        const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
          method: 'POST',
          headers: {
            Authorization: `${token}`,
            'AccessTicket-App': 'manager',
          },
          body: JSON.stringify({
            query: TransferTicketManager,
            variables: {
              email: transferEmail,
              publicId: data.stored.publicId,
            },
          }),
        })
        const response = await res.json()

        if (response && response.errors && response.errors.length) {
          alert('Error ' + response.errors?.[0]?.message)
          setErrors(response.errors?.[0]?.message)
        } else {
          alert('Ticket transfered successfully')
          window.location.reload()
        }
      } catch (err) {
        console.log('err', err)
        setErrors(['API ERROR'])
      }
    }
  }

  const onChangeRefundPurchase = (key, value) => {
    setRefundPurchaseForm(state => ({ ...state, [key]: value }))
  }

  const hasMoreTwoDecimals = (number) => {
    const parts = number.split('.');
    return parts[1] && parts[1].length > 2;
  }


  const handleRefundPruchase = async (e, selectedRefundPurchase, refundPurchaseForm) => {
    e.preventDefault()
    setLoading(true)
    try {
      const { _id, type, orderId, } = selectedRefundPurchase
      const { reason, amount } = refundPurchaseForm

      const query = type === "marketplacePurchase" ? refundRedsysMarketplacePurchase : RefundRedsysPurchase
      const variables = type === "marketplacePurchase"
        ? {
          orderId,
          ticketId: _id,
          reason,
        }
        : {
          purchaseId: _id,
          reason,
          amount,
        }

      if (hasMoreTwoDecimals(amount)) {
        setRefundPurchaseResponse({
          ok: false,
          message: "The amount should only have 2 decimal places"
        })
        throw "The amount should only have 2 decimal places"
      }

      const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
        method: 'POST',
        headers: {
          Authorization: `${token}`,
          'AccessTicket-App': 'manager',
        },
        body: JSON.stringify({
          query,
          variables
        }),
      })

      if (type === "marketplacePurchase") {
        const { data } = await res.json()
        const [error] = data?.errors || []

        setRefundPurchaseResponse({
          ok: !error,
          message: error?.message || data?.refundRedsysMarketplacePurchase?.message
        })

        if (!error) {
          window.location.reload()
        }
      } else {
        const { data } = await res.json()
        const [error] = data?.errors || []

        setRefundPurchaseResponse({
          ok: !error,
          message: error?.message || data?.refundRedsysPurchase?.message
        })

        loadPurchases()
      }
    } catch (err) {
      console.log('🚀 ~ file: EditTickets.js:759 ~ handleRefundPruchase ~ err:', err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setRefundPurchaseForm({ reason: '', confirm: '', amount: '0', refundTotal: false })
    setRefundPurchaseResponse({ ok: false, message: "" })
  }, [selectedRefundPurchase])

  return (
    <div>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>

      {showCamera && (
        <ModalCaptureImage
          hasBeenUsed={(data?.stored?.hasBeenUsed && data?.hasEntrances) || false}
          newSelfie={newSelfie}
          show={showCamera}
          showState={setshowCamera}
          ticketId={data && data.stored && data.stored.publicId}
        />
      )}

      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-xl wght-semibold">{title}</h2>
          {
            role &&
            <div className='flex'>
              <div className="relative flex text-xs text-gray wght-light">
                <div>
                  <span className='wght-semibold'>{role.toUpperCase()}</span>
                </div>
              </div>
            </div>
          }

          {role !== 'read' && data && data.stored && (data.stored.publicId || data.stored.ticketId) && !data.stored.refunded &&
            <div className='flex mt-2'>

              {data && data.stored && data.stored.deleted ?
                <Button size="small" onClick={() => remove(id)} disabled={saving} className='mx-0 mr-0.5 ml-0' color='success'>Recover ticket</Button>
                :
                <>
                  {role === 'admin' ? (
                    <>
                      <Button size="small" onClick={() => duplicate(id)} disabled={saving} className='mx-0 mr-0.5 ml-0'>Clone</Button>
                      <Button size="small" onClick={() => remove(id)} disabled={saving} className="mr-0.5" color="error">Delete</Button>
                    </>) : null}
                </>
              }
            </div>
          }
        </div>
        <Link
          to={`/admin/${event}/list/Ticket`}
          className="cursor-pointer hover:wght-semibold hover:text-error"
        >
          Cancel
        </Link>
      </div>

      {data && data.stored && data.stored.refunded &&
        <div className='p-2 my-4 text-white rounded bg-error'>
          <span>This ticket has been refunded.</span>
        </div>
      }

      {data.stored?.deleted && !data.stored.refunded &&
        <div className='p-2 my-4 text-white rounded bg-error'>
          <span>This ticket is deleted. To recover it, click on 'Recover ticket'.</span>
        </div>
      }
      {data.stored?.marketplace?.sold &&
        <div className='p-2 my-4 text-white rounded bg-blue'>
          {`This ticket was sold on ${dayjs(data.stored.marketplace.soldDate, DEFAULT_TIMEZONE).format('DD/MM/YYYY HH:mm:ss')}`}
        </div>
      }

      {data && data.stored && (data?.stored?.hasBeenUsed && data?.hasEntrances) &&
        <div className='flex items-center justify-between p-2 my-4 text-white rounded bg-warning'>
          <p>This ticket has already accessed the event.</p>
          {firstEntrance ? <p>{firstEntrance.zone}: {dayjs(firstEntrance.createdAt, DEFAULT_TIMEZONE).format('DD/MM/YYYY HH:mm:ss')}</p>
            :
            <Button size="small" color="warningWhite" onClick={handleGetEntranceTime} className='h-full'>Show first entrance</Button>
          }
        </div>
      }

      {!data && <div className="mt-8 mb-8">Loading...</div>}

      {selectedRefundPurchase
        && <Modal showModal={selectedRefundPurchase} setShowModal={setSelectedRefundPurchase} background="white">
          <div className="relative p-4 overflow-auto bg-white z-1 max-h-128">
            <CloseButton setShowModal={setSelectedRefundPurchase} />
            <h2 className="text-lg wght-semibold">Refund confirmation</h2>

            {
              data.stored?.hasBeenUsed && selectedRefundPurchase?.type === "marketplacePurchase"
              && <div className='p-2 mt-4 text-xs text-white rounded bg-error'>
                <span>This ticket has been used.</span>
              </div>
            }

            <form className="inline-flex flex-col mt-4">
              <span className='inline-block w-full'>
                <label className="mb-1 text-sm" htmlFor="reason">Refund reason &#40;required&#41;</label>
                <textarea
                  name="reason"
                  className="w-full px-2 py-1 rounded outline-none bg-grayLight placeholder-gray text-s"
                  type="text"
                  placeholder="Refund reason"
                  value={refundPurchaseForm.reason}
                  onChange={(e) => onChangeRefundPurchase('reason', e.target.value)}
                />
              </span>

              {
                selectedRefundPurchase?.type !== "marketplacePurchase" &&
                <span className="inline-block">
                  <label className="mb-1 text-sm" htmlFor="amount">Amount &#40;required&#41; </label>
                  <input
                    name="amount"
                    className="w-full px-2 py-1 rounded outline-none bg-grayLight placeholder-gray text-s"
                    type="number"
                    defaultValue={0}
                    step="0.01"
                    min="0"
                    value={refundPurchaseForm.amount}
                    onChange={(e) => onChangeRefundPurchase('amount', e.target.value)}
                  />
                </span>
              }

              <span className="inline-block mt-6">
                <label className="mb-1 text-sm" htmlFor="typeConfirm">Type <b>"CONFIRM"</b> to proceed:</label>
                <input
                  name="confirm"
                  className="w-full px-2 py-1 rounded outline-none bg-grayLight placeholder-gray text-s"
                  type="text"
                  placeholder="CONFIRM"
                  value={refundPurchaseForm.confirm}
                  onChange={(e) => onChangeRefundPurchase('confirm', e.target.value)}
                />
              </span>

              <div className="flex flex-wrap items-end justify-end mt-4">
                {refundPurchaseResponse.message && (
                  <div className="flex items-center w-full">
                    <span className={`text-xs ${refundPurchaseResponse.ok ? "text-success" : "text-error"}`}>
                      {refundPurchaseResponse.message}
                    </span>
                  </div>
                )}

                <div className="flex justify-end">
                  {refundPurchaseResponse.ok
                    && <Button
                      onClick={() => setSelectedRefundPurchase(false)}
                      className="px-4 py-1 text-xs "
                      color="success"
                    >
                      Done
                    </Button>
                  }
                  {
                    !refundPurchaseResponse.ok
                    && <Button
                      disable={!refundPurchaseForm.reason || loading || refundPurchaseForm.confirm !== "CONFIRM" || (!refundPurchaseForm.refundTotal && !Number(refundPurchaseForm.amount) && !selectedRefundPurchase?.type)}
                      onClick={(e) => handleRefundPruchase(
                        e,
                        selectedRefundPurchase,
                        refundPurchaseForm
                      )}
                      className="px-4 py-1 text-xs "
                      color="primary"
                    >
                      {loading ? "Loading..." : "Continue"}
                    </Button>
                  }
                </div>
              </div>
            </form>
          </div>
        </Modal>
      }

      {selectedRefundPurchaseDetails
        && <Modal showModal={selectedRefundPurchaseDetails} setShowModal={setSelectedRefundPurchaseDetails} background="white">
          <div className="relative p-4 overflow-auto bg-white z-1 max-h-128">
            <CloseButton setShowModal={setSelectedRefundPurchaseDetails} />
            <h2 className="text-lg wght-semibold">Refund details</h2>

            <div className="inline-flex flex-col mt-4">
              <span className='inline-block'>
                <label className="mb-1 text-sm" htmlFor="typeConfirm">Order id</label>
                <input
                  readOnly
                  name="orderId"
                  className="w-full px-2 py-1 rounded outline-none bg-grayLight placeholder-gray text-s"
                  type="text"
                  value={selectedRefundPurchaseDetails.orderId}
                />
              </span>

              <span className='inline-block'>
                <label className="mb-1 text-sm" htmlFor="typeConfirm">Status</label>
                <input
                  readOnly
                  name="status"
                  className="w-full px-2 py-1 rounded outline-none bg-grayLight placeholder-gray text-s"
                  type="text"
                  value={selectedRefundPurchaseDetails.status}
                />
              </span>

              <span className='inline-block'>
                <label className="mb-1 text-sm" htmlFor="typeConfirm">Total refund amount</label>
                <input
                  readOnly
                  name="amount"
                  className="w-full px-2 py-1 rounded outline-none bg-grayLight placeholder-gray text-s"
                  type="number"
                  value={selectedRefundPurchaseDetails.refundedPaymentInfo.amount}
                />
              </span>

              <h3 className="mt-1 text-sm">History</h3>

              <div className="mt-1 overflow-scroll">
                <table className="w-full overflow-x-auto bg-white border border-grayLight">
                  <thead className="w-full text-left borde-b border-grayLight">
                    <tr className="border-b border-grayLight">
                      <th className="px-4 py-2">
                        <span className="relative text-xs wght-normal whitespace-nowrap">STATUS</span>
                      </th>
                      <th className="px-4 py-2">
                        <span className="relative text-xs wght-normal whitespace-nowrap">AMOUNT</span>
                      </th>
                      <th className="px-4 py-2">
                        <span className="relative text-xs wght-normal whitespace-nowrap">REFUNDED BY</span>
                      </th>
                      <th className="px-4 py-2">
                        <span className="relative text-xs wght-normal whitespace-nowrap">Reason</span>
                      </th>
                      <th className="px-4 py-2">
                        <span className="relative text-xs wght-normal whitespace-nowrap">DATE</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {selectedRefundPurchaseDetails.history.map((element) => (
                      <tr
                        key={"refund-purchase-detail-" + element._id}
                        className="relative align-top bg-white border-b border-grayLight hover:bg-primaryLight"
                      >
                        <td className="px-4 py-2 text-xs">{element.data.status || '-'}</td>
                        <td className="px-4 py-2 text-xs">{element.data.amount}€</td>
                        <td className="px-4 py-2 text-xs ">{element.data?.refundedBy?.name}</td>
                        <td className="px-4 py-2 text-xs ">
                          <textarea
                            readOnly={true}
                            name="reason"
                            className="w-full p-2 px-2 py-1 border rounded outline-none placeholder-gray text-s border-grayLight"
                            type="text"
                            placeholder="Refund reason"
                            value={element.data.reason}
                          />
                        </td>
                        <td className="px-4 py-2 text-xs ">{dayjs(element.date).format('DD/MM/YYYY HH:mm:ss')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      }

      {data && modelName === name && (
        <div className={`mt-8`}>
          <Form
            onChange={updateStored}
            data={data}
            name={name}
            showErrors={showErrors}
            mode={id ? 'edit' : 'create'}
            currentData={data}
            readMode={loadingFields || readMode}
          />

          {loadingFields && <p className='mt-4 mb-1'>Loading fields...</p>}
          <div className='mt-4 mb-4'>

            <div className='bg-white'>
              <Section title="Fields" open={visibleSections.includes('fields')} onClick={() => handleVisibleSections('fields')}>
                {!loadingFields && ticketFields && ticketFields.length > 0 &&
                  <div className='bg-white'>
                    {ticketFields.map((field, index) => (<span className='inline-block w-1/2 p-2' key={`ticketField-${index}`}>
                      <label className='text-sm'>{field}{field === 'birthDate' && ` (⚠️ format: YYYY-MM-DD)`}</label>
                      <input
                        readOnly={readMode}
                        name={field}
                        className={`w-full rounded px-2 py-1 bg-grayLight
                          ${false ? 'opacity-50' : ''} outline-none placeholder-gray`}
                        type="text"
                        placeholder={field === 'birthDate' ? 'YYYY-MM-DD' : field}
                        value={data.stored.fields && data.stored.fields[field] || ''}
                        onChange={(e) => onChangeField(field, e.target.value)}
                      />
                    </span>)
                    )}
                  </div>
                }
              </Section>
            </div>

            {data && data.stored &&
              <Section title="User" open={visibleSections.includes('user')} onClick={() => handleVisibleSections('user')}>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>User Id</label>
                  <input
                    readOnly={true}
                    name="userId"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.userId || ''}
                  />
                </span>

                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>User email</label>
                  <input
                    readOnly={true}
                    name="userEmail"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.userEmail || ''}
                  />
                </span>

                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Device id</label>
                  <input
                    readOnly={true}
                    name="deviceId"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.deviceId || ''}
                  />
                </span>

                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Exchange date</label>
                  <input
                    readOnly={true}
                    name="exchangeDate"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.exchangeDate && dayjs(data.stored.exchangeDate).isValid() ? dayjs(data.stored.exchangeDate).format('DD/MM/YYYY HH:mm:ss') : ''}
                  />
                </span>

                <span className='inline-block w-1/4 p-2'>
                  <label className='text-sm'>Email Verification Date</label>
                  <input
                    readOnly={true}
                    name="verificationCode"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.verificationCode && data.stored.verificationCode.date && dayjs(data.stored.verificationCode.date).isValid() ? dayjs(data.stored.verificationCode.date).format('DD/MM/YYYY HH:mm:ss') : '' || ''}
                  />
                </span>

                <span className='inline-block w-1/4 p-2'>
                  <label className='text-sm'>Email Verification Code</label>
                  <input
                    readOnly={true}
                    name="verificationCode"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.verificationCode && data.stored.verificationCode.code || ''}
                  />
                </span>

                <span className='inline-flex justify-between w-full p-2'>
                  <div className='flex'>
                    <span>
                      <label className='text-sm'>Transfer email</label>
                      <input
                        readOnly={readMode}
                        name="verificationCode"
                        className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                        type="text"
                        placeholder="Email"
                        value={transferEmail}
                        onChange={(e) => setTransferEmail(e.target.value)}
                      />
                    </span>
                    <span className='flex items-end ml-2'>
                      <Button
                        disable={readMode}
                        visibility={data?.stored?.userId || data?.stored?.deviceId}
                        onClick={transferTicket}
                        size="small"
                        color='cancel'
                      >Transfer ticket to another user</Button>
                    </span>
                  </div>
                  <span className='flex items-end'>
                    <Button
                      disable={readMode}
                      visibility={data?.stored?.userId || data?.stored?.deviceId}
                      onClick={removeUserData}
                      size="small"
                      color='cancel'
                    >Remove user from this ticket</Button>
                  </span>
                </span>
              </Section>
            }

            {data && data.stored &&
              <Section title="KYC" open={visibleSections.includes('kyc')} onClick={() => handleVisibleSections('kyc')}>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Type</label>
                  <input
                    readOnly={true}
                    name="kycType"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.kycType || ''}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Date</label>
                  <input
                    readOnly={true}
                    name="kycDate"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.validation && data.stored.validation[data.stored.kycType] && data.stored.validation[data.stored.kycType].updateDate && dayjs(data.stored.validation[data.stored.kycType].updateDate).format('DD/MM/YYYY HH:mm:ss') || ''}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <div>
                    <p className='text-sm'>Selfie</p>
                    {newSelfie ?
                      <div className='mt-2'>
                        <img src={newSelfie} className="rounded" />
                      </div>
                      :
                      <>
                        {data?.stored?.validationImage ?
                          <div>
                            <img src={data.stored.validationImage} className="mt-2 rounded cursor-pointer w-52 " onClick={() => setShowFullscreenImage(true)} />
                            <Modal showModal={showFullscreenImage} setShowModal={setShowFullscreenImage}/*  */ >
                              <CloseButton setShowModal={setShowFullscreenImage} />
                              <img src={data.stored.validationImage} className="w-1/2 mt-2 rounded" />
                            </Modal>
                          </div>
                          : <p className='mt-2 text-xs text-error'>No selfie</p>
                        }
                      </>
                    }
                    <div className='flex mt-2'>
                      {data?.stored?.validationImage && data?.stored?.validationImageType === 'sumsub' &&
                        <Button size="small" className='' onClick={refreshSelfie}>Refresh</Button>
                      }
                      {
                        data?.stored?.validationImage &&
                        <Button size="small" className='' color='error' onClick={deleteSelfie}>Delete</Button>
                      }
                      <Button disable={readMode} size="small" className=''>
                        <label className="cursor-pointer ">
                          <span className="">Import</span>
                          <input
                            disabled={readMode}
                            onChange={handleUploadFile}
                            type="file"
                            name="file"
                            value=""
                            title="&nbsp;"
                            style={{ display: 'none' }}
                          />
                        </label>
                      </Button>
                      <Button disable={readMode} size="small" className='' onClick={handleOpenCamera}>Take selfie</Button>
                    </div>
                  </div>
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <input
                    disabled={readMode}
                    className='mr-1 cursor-pointer' type="checkbox" name="kycValid" id="kycValid"
                    checked={(
                      data.stored.validation
                      && data.stored.validation.sumsub
                      && data.stored.validation.sumsub.valid
                    )}
                    onChange={(e) => updateKYC(e.target.value)}
                  />
                  { }
                  <label className='mr-2 cursor-pointer select-none' htmlFor="kycValid">Valid</label>
                </span>

                <span className='absolute bottom-0 right-0 flex justify-end w-auto p-2'>
                  {data.stored?.validation
                    && data.stored?.kycType === 'sumsub'
                    && data.stored?.validation[data.stored.kycType]?.applicantId &&
                    <a
                      target={'_blank'}
                      href={`https://cockpit.sumsub.com/checkus#/applicant/${data.stored.validation[data.stored.kycType].applicantId}/basicInfo?clientId=primaverasound.com_30308`}
                      className="px-1 py-0.5 mx-0.5 text-xs text-white rounded cursor-pointer hover:bg-black bg-primary"
                    >
                      Sumsub panel
                    </a >
                  }
                  {
                    data?.stored?.validation && data?.stored?.validation[data?.stored?.kycType] &&
                    <span className="px-1 py-0.5 mx-0.5 text-xs text-white rounded cursor-pointer hover:bg-black bg-error" onClick={removeKYC}>Remove KYC</span>
                  }
                </span >

                <span className="inline-flex flex-col w-1/2 p-2">
                  <label className='text-sm'>Accreditation</label>
                  <div className='flex my-1'>
                    <Button disable={loading || readMode} size="small" className="w-fit" onClick={handlePrintAccreditation}>
                      {loading ? 'Loading...' : 'Print'}
                    </Button>
                  </div>
                </span>
              </Section >
            }

            {
              data && data.stored &&
              <Section title="Info" open={visibleSections.includes('info')} onClick={() => handleVisibleSections('info')}>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>First name</label>
                  <input
                    readOnly={readMode}
                    name="firstName"
                    className={`w-full rounded px-2 py-1 bg-grayLight outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.info?.firstName || ''}
                    onChange={(e) => onChangeInfoKYC('firstName', e.target.value)}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Last name</label>
                  <input
                    readOnly={readMode}
                    name="lastName"
                    className={`w-full rounded px-2 py-1 bg-grayLight outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.info?.lastName || ''}
                    onChange={(e) => onChangeInfoKYC('lastName', e.target.value)}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Birthdate (⚠️ format: YYYY-MM-DD)</label>
                  <input
                    readOnly={readMode}
                    name="birthDate"
                    className={`w-full rounded px-2 py-1 bg-grayLight outline-none placeholder-gray`}
                    type="text"
                    placeholder="YYYY-MM-DD"
                    value={data.stored.info?.birthDate || ''}
                    onChange={(e) => onChangeInfoKYC('birthDate', e.target.value)}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>ID card</label>
                  <input
                    readOnly={readMode}
                    name="idCard"
                    className={`w-full rounded px-2 py-1 bg-grayLight outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.info?.idCard || ''}
                    onChange={(e) => onChangeInfoKYC('idCard', e.target.value)}
                  />
                </span>
                {data.stored.info?.birthDate && dayjs().diff(dayjs(data.stored.info?.birthDate), 'year') < 18 && <>
                  <span className='inline-block w-1/2 p-2'>
                    <label className='text-sm'>Minor allowed inside </label>
                    <input
                      disabled={readMode}
                      name="minorAllowed"
                      className={``}
                      type="checkbox"
                      checked={data.stored?.info?.minorAllowed}
                      onChange={(e) => onChangeInfoKYC('minorAllowed', e.target.checked)}
                    />
                  </span>
                </>}
              </Section>
            }

            {
              data && data.stored &&
              <Section title="Guests Invitation" open={visibleSections.includes('guestsInvitation')} onClick={() => handleVisibleSections('guestsInvitation')}>
                {data.stored.guestsInvitation ? <>
                  {
                    Object.keys(data.stored.guestsInvitation).map((key, index) => {
                      if (key === 'name') {
                        return <span className='inline-block w-1/2 p-2' key={`${key}-${index}`}>
                          <label className='text-sm'>Name</label>
                          <input
                            readOnly={true}
                            name="name"
                            className={`w-full rounded px-2 py-1 bg-grayLight outline-none placeholder-gray`}
                            type="text"
                            placeholder="empty"
                            value={data.stored.guestsInvitation?.name || ''}
                          />
                        </span>
                      }
                      if (key === 'lastname') {
                        return <span className='inline-block w-1/2 p-2' key={`${key}-${index}`}>
                          <label className='text-sm'>Lastname</label>
                          <input
                            readOnly={true}
                            name="lastname"
                            className={`w-full rounded px-2 py-1 bg-grayLight outline-none placeholder-gray`}
                            type="text"
                            placeholder="empty"
                            value={data.stored.guestsInvitation?.lastname || ''}
                          />
                        </span>
                      }
                      if (key === 'email') {
                        return <span className='inline-block w-1/2 p-2' key={`${key}-${index}`}>
                          <label className='text-sm'>email</label>
                          <input
                            readOnly={true}
                            name="email"
                            className={`w-full rounded px-2 py-1 bg-grayLight outline-none placeholder-gray`}
                            type="text"
                            placeholder="empty"
                            value={data.stored.guestsInvitation?.email || ''}
                          />
                        </span>
                      }
                    })
                  }

                </> : <></>
                }
              </Section>
            }

            {
              data && data.stored &&
              <Section title="Original Ticket" open={visibleSections.includes('originalTicket')} onClick={() => handleVisibleSections('originalTicket')}>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Ticket ID Original</label>
                  <input
                    readOnly={true}
                    name="ticketIdOriginal"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.originalTicket?.ticketIdOriginal || ''}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Order ID Original</label>
                  <input
                    readOnly={true}
                    name="orderIdOriginal"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.originalTicket?.orderIdOriginal || ''}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Info</label>
                  <input
                    readOnly={true}
                    name="internalTicketInfo"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.originalTicket?.internalTicketInfo || ''}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Purchase email</label>
                  <input
                    readOnly={true}
                    name="purchaseEmail"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.originalTicket?.purchaseEmail || ''}
                  />
                </span>


                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Point of sale</label>
                  <input
                    readOnly={true}
                    name="pointOfSale"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.pointOfSale || ''}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Import origin source</label>
                  <input
                    readOnly={true}
                    name="importOriginSource"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.importOriginSource || ''}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Import batch ID</label>
                  <input
                    readOnly={true}
                    name="importBatchId"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored.importBatchId || ''}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <label className='text-sm'>Purchase Date</label>
                  <input
                    readOnly={true}
                    name="purchaseDate"
                    className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                    type="text"
                    placeholder="empty"
                    value={data.stored?.originalTicket?.purchaseDate && dayjs(data.stored.originalTicket.purchaseDate).format('DD/MM/YYYY HH:mm:ss') || ''}
                  />
                </span>
              </Section>
            }

            {
              data && data.stored && walletTypes &&
              <Section title="Wallet" open={visibleSections.includes('wallet')} onClick={() => handleVisibleSections('wallet')}>
                <span className='inline-block w-1/2 p-2'>
                 <label className='text-sm'>Square Wallet</label>
                  <input
                    readOnly={true}
                    name="gan"
                    className={`w-full rounded px-2 py-1 bg-grayLight
                  ${false ? 'opacity-50' : ''} outline-none placeholder-gray`}
                    type="text"
                    value={data.rawTicket?.[0]?.gan?.gan}
                  />
                </span>
                <span className='inline-block w-1/2 p-2'>
                  <a
                    target={'_blank'}
                    href={`https://app.squareup.com/gift/ML0WQ25PTZVPM/view/plastic`}
                    className="px-1 py-0.5 mx-0.5 text-xs text-white rounded cursor-pointer hover:bg-black bg-primary"
                  >
                    Check credit on square
                  </a >
                </span>
                {/* {walletTypes.map(walletType => {
                  const typeTitle = `${walletType.charAt(0).toUpperCase()}${walletType.slice(1)}`
                  return (<span className='inline-block w-1/2 p-2' key={`wtype-${walletType}`}>
                    <label className='text-sm'>{typeTitle}</label>
                    <input
                      readOnly={readMode}
                      name={typeTitle}
                      className={`w-full rounded px-2 py-1 bg-grayLight
                    ${false ? 'opacity-50' : ''} outline-none placeholder-gray`}
                      type="number"
                      step=".01"
                      placeholder={typeTitle}
                      value={walletData && walletData[walletType]}
                      onChange={(e) => onChangeWallet(walletType, e.target.value)}
                    />
                  </span>)
                })} */}
              </Section>
            }

            {
              data && data.stored &&
              <Section title="Consumables" open={visibleSections.includes('consumables')} onClick={() => handleVisibleSections('consumables')}>
                {consumables && consumables.length > 0 &&
                  <table className='mb-2'>
                    <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
                      <tr>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Status</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Product</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consumables && consumables.map(consumable => <tr key={consumable._id} className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight">
                        <td className={`text-white text-center px-4 py-2 whitespace-nowrap ${consumable.exchanged === true ? 'bg-success' : 'bg-error'}`}>{consumable.status || 'NOT EXCHANGED'}</td>
                        <td className="px-4 py-2 whitespace-nowrap">{consumable.title}</td>
                        <td className="px-4 py-2 whitespace-nowrap">{consumable.date && dayjs(consumable.date).format('DD-MM-YYYY HH:mm:ss') || "--"}</td>
                      </tr>
                      )}
                    </tbody>
                  </table>
                }

                {(!consumables) && <Button size="small" color="white" onClick={loadConsumables}>Load consumables</Button>}
                {consumables && consumables.length === 0 && <p>No consumables</p>}
              </Section>
            }

            {
              data && data.stored &&
              <Section title="Purchases" open={visibleSections.includes('purchases')} onClick={() => handleVisibleSections('purchases')}>
                {purchases && purchases.length > 0 &&
                  <table className='w-full mb-2'>
                    <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
                      <tr>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Status</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Purchase Date</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Exchange Date</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Order ID</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Product</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Total price</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Wallet</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Total paid</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Card OrderId</th>
                        {role === 'admin' && <th className="relative px-4 py-2 text-left wght-semibold ">Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {purchases && purchases.map((info, index) => (
                        <tr
                          key={index}
                          className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight"
                        >
                          <th className="px-4 py-2 whitespace-nowrap">
                            <p>{info.status}</p>
                          </th>
                          <th className="px-4 py-2 whitespace-nowrap">
                            {dayjs(info.creationDate).format('DD/MM/YYYY HH:mm:ss')}
                          </th>
                          <th className="px-4 py-2 whitespace-nowrap">
                            {(info?.exchangeDate && dayjs(info?.exchangeDate).isValid()) ? dayjs(info.exchangeDate).format('DD/MM/YYYY HH:mm:ss') :
                              <span className="flex items-center justify-center w-full">-</span>}
                          </th>
                          <th className="px-4 py-2 whitespace-nowrap">
                            {
                              info.paidPaymentInfo?.orderId ?
                                <p>{info.paidPaymentInfo?.orderId}</p> :
                                <span className="flex items-center justify-center w-full">-</span>
                            }
                          </th>
                          <th className="px-4 py-2">
                            {info.products.map((product, index) => (
                              <div key={index} className={`${info.products?.length > 1 && index < info?.products.length - 1 ? 'border-b border-grayLight' : ''}`}>
                                <div className="flex justify-start">
                                  <span className="mr-2">{product.units}</span>
                                  <span className="w-full mr-2">{product.title.en}</span>
                                  <span className="whitespace-nowrap">{product.totalPrice} €</span>
                                </div>
                                <span className="text-xxs text-gray">{product?.sections.map(section => section?.options.map(option => option.title.en).join(', ')).filter(a => a.trim() !== '').join(', ')}</span>
                              </div>

                            ))}
                          </th>
                          <th className="px-4 py-2">{info.priceWithoutWallet} €</th>
                          <th className="px-4 py-2">{Object.entries(info.usedWallet).map(e => `${e[0]}: -${e[1]} €`)}</th>
                          <th className="px-4 py-2">{info.totalPrice} €</th>
                          <th className="px-4 py-2">{info.orderId || '-'}</th>
                          {role === 'admin'
                            && <th className="px-4 py-2">
                              <div className="flex items-center justify-start h-full">
                                {(["PAID", "PARTIALLY_REFUNDEDPAID", "ERROR_REFUNDEDPAID"].includes(info.status))
                                  && <Button
                                    size="small"
                                    onClick={() => setSelectedRefundPurchase(info)}
                                    disabled={saving}
                                    color="error"
                                  >
                                    Refund
                                  </Button>
                                }
                                {
                                  (["REFUNDEDPAID", "PARTIALLY_REFUNDEDPAID", "ERROR_REFUNDEDPAID"].includes(info.status))
                                  && <Button
                                    size="small"
                                    onClick={() => setSelectedRefundPurchaseDetails(info)}
                                    disabled={saving}
                                    color="primary"
                                    className="whitespace-nowrap ml-0.5"
                                  >
                                    Refunded details
                                  </Button>
                                }
                              </div>
                            </th>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }

                {(!purchases) && <Button size="small" color="white" onClick={loadPurchases}>Load purchases</Button>}
                {purchases && purchases.length === 0 && <p>No purchases</p>}
              </Section>
            }

            {
              data && data.stored &&
              <Section title="Others" open={visibleSections.includes('others')} onClick={() => handleVisibleSections('others')}>
                <span className='inline-block w-1/2 p-2'>
                  <p className='mb-1 text-sm'>Wristband Code</p>
                  <input
                    readOnly={readMode}
                    name="wristbandCode"
                    className={`w-full px-2 py-1 rounded bg-grayLight outline-none resize-y ${wristBandCodeError && 'border border-error'}`}
                    placeholder={'Comment'}
                    value={data.stored?.wristbandCode || ''}
                    onChange={e => updateStored('wristbandCode', e.target.value.toUpperCase())}
                  />
                  {wristBandCodeError &&
                    <p className="pr-2 text-error">Wristband code already exists</p>
                  }
                </span>
                {data?.requirements && data.requirements.includes('manual') &&
                  <span className='inline-block w-1/2 p-2'>
                    <p className='mb-1'>
                      {`Manual validation: `}
                    </p>
                    <div className='flex'>
                      {data?.stored?.validation?.manual?.valid ?
                        <span className='w-20 p-1 mr-2 text-center border rounded text-success border-success'>VALID</span>
                        :
                        <span className='w-20 p-1 mr-2 text-center border rounded text-error border-error'>NOT VALID</span>
                      }
                      <Button size="small" onClick={switchManualValidation}>Switch validation</Button>
                    </div>
                  </span>
                }
                <span className='inline-block w-1/2 p-2'>
                  <input
                    disabled={readMode}
                    className='mr-1 cursor-pointer' type="checkbox" name="hasShirt" id="hasShirt"
                    checked={(data.stored?.hasShirt)}
                    onChange={e => updateStored('hasShirt', !data.stored?.hasShirt)}
                  />
                  <label className='mr-2 cursor-pointer select-none' htmlFor="hasShirt">Has Shirt</label>
                </span>
                <span className='inline-block w-full p-2'>
                  <p className='mb-1 text-sm'>Manager comment</p>
                  <textarea
                    readOnly={readMode}
                    name="managerComment"
                    className={`w-full px-2 py-1 rounded bg-grayLight outline-none resize-y`}
                    placeholder={'Comment'}
                    value={data.stored?.managerComment || ''}
                    onChange={e => updateStored('managerComment', e.target.value)}
                  />
                </span>
                {modelName === 'Ticket' && data && data.stored && data.stored.ticketId &&
                  <span className='inline-block w-1/2 p-2'>
                    <p className='mb-1'>QR Code</p>
                    <QRCode size={100} value={`${data.stored.ticketId}`} /><span>{data.stored.ticketId}</span>
                  </span>
                }
              </Section>
            }

            {
              data && data.stored &&
              <Section title="Marketplace" open={visibleSections.includes('marketplace')} onClick={() => handleVisibleSections('marketplace')}>
                {data.stored.marketplace?.marketplacePurchase &&
                  <>
                    <span className='inline-block w-1/2 p-2'>
                      <label className='text-sm'>Price</label>
                      <input
                        readOnly={true}
                        name="price-publicId"
                        className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                        type="text"
                        placeholder="empty"
                        value={data.stored.marketplace?.marketplacePurchase?.totalPrice || ''}
                      />
                    </span>

                    <span className='inline-block w-1/2 p-2'>
                      <label className='text-sm'>Status</label>
                      <input
                        readOnly={true}
                        name="price-publicId"
                        className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                        type="text"
                        placeholder="empty"
                        value={data.stored.marketplace?.marketplacePurchase?.status || ''}
                      />
                    </span>

                    <span className='inline-block w-1/2 p-2'>
                      <label className='text-sm'>Creation date</label>
                      <input
                        readOnly={true}
                        name="price-publicId"
                        className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                        type="text"
                        placeholder="empty"
                        value={data.stored.marketplace?.marketplacePurchase?.creationDate
                          && dayjs(data.stored.marketplace?.marketplacePurchase?.creationDate).isValid()
                          ? dayjs(data.stored.marketplace?.marketplacePurchase?.creationDate).format('DD/MM/YYYY HH:mm:ss') : ''}
                      />
                    </span>

                    {role === "admin" && (!data.stored?.refunded || !data.stored?.deleted) && data.stored?.marketplace?.marketplacePurchase?.paidPaymentInfo?.orderId
                      && <span className='inline-block w-1/2 p-2'>
                        <label className='text-sm'>Refund marketplace</label>
                        <Button
                          size="small"
                          onClick={() => setSelectedRefundPurchase({
                            orderId: data.stored.marketplace?.marketplacePurchase?.paidPaymentInfo?.orderId,
                            _id: data.stored._id,
                            type: 'marketplacePurchase'
                          })}
                          disabled={saving}
                          color='error'
                        >
                          Refund
                        </Button>
                      </span>
                    }
                  </>
                }
                {data.stored.marketplace?.marketplacePurchase?.history && data.stored.marketplace?.marketplacePurchase?.history.length !== 0 &&
                  <>
                    <h3 className="mt-1 ml-2 text-sm">Refund history</h3>

                    <div className="p-2 pt-1 overflow-scroll">
                      <table className="w-full overflow-x-auto bg-white border border-grayLight">
                        <thead className="w-full text-left borde-b border-grayLight">
                          <tr className="border-b border-grayLight">
                            <th className="px-4 py-2">
                              <span className="relative text-xs wght-normal whitespace-nowrap">TICKET ID</span>
                            </th>
                            <th className="px-4 py-2">
                              <span className="relative text-xs wght-normal whitespace-nowrap">STATUS</span>
                            </th>
                            <th className="px-4 py-2">
                              <span className="relative text-xs wght-normal whitespace-nowrap">AMOUNT</span>
                            </th>
                            <th className="px-4 py-2">
                              <span className="relative text-xs wght-normal whitespace-nowrap">REFUNDED BY</span>
                            </th>
                            <th className="px-4 py-2">
                              <span className="relative text-xs wght-normal whitespace-nowrap">REASON</span>
                            </th>
                            <th className="px-4 py-2">
                              <span className="relative text-xs wght-normal whitespace-nowrap">DATE</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.stored.marketplace.marketplacePurchase.history.map((element, index) => (
                            <tr
                              key={"refund-marketplace-purchase-detail-" + index}
                              className="relative align-top bg-white border-b border-grayLight hover:bg-primaryLight"
                            >
                              <td className="px-4 py-2 text-xs">{element.data.ticket.ticketId || '-'}</td>
                              <td className="px-4 py-2 text-xs">{element.data.status || '-'}</td>
                              <td className="px-4 py-2 text-xs">{element.data.amount}€</td>
                              <td className="px-4 py-2 text-xs ">{element.data?.refundedBy?.name}</td>
                              <td className="px-4 py-2 text-xs ">
                                <textarea
                                  readOnly={true}
                                  name="reason"
                                  className="w-full p-2 px-2 py-1 border rounded outline-none placeholder-gray text-s border-grayLight"
                                  type="text"
                                  placeholder="Refund reason"
                                  value={element.data.reason}
                                />
                              </td>
                              <td className="px-4 py-2 text-xs ">{dayjs(element.date).format('DD/MM/YYYY HH:mm:ss')}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                }

                <span className='inline-block w-full p-2 pb-0'>
                  <label className='text-large'>Enabling tickets:</label>
                </span>

                {
                  data.stored.marketplace?.enablingTicketIds ? data.stored.marketplace.enablingTicketIds?.map(ticket => (
                    <div key={ticket._id} className="relative m-4 mt-2 border border-grayLight">
                      <span className='inline-block w-1/2 p-2'>
                        <label className='text-sm'>Ticket ID</label>
                        <input
                          readOnly={true}
                          name="enablingTicket-ticketId"
                          className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                          type="text"
                          placeholder="empty"
                          value={ticket.ticketId || ''}
                        />
                      </span>

                      <span className='inline-block w-1/2 p-2'>
                        <label className='text-sm'>Ticket Type</label>
                        <input
                          readOnly={true}
                          name="enablingTicket-ticketType"
                          className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                          type="text"
                          placeholder="empty"
                          value={ticket.ticketType || ''}
                        />
                      </span>

                      <span className='inline-block w-1/2 p-2'>
                        <label className='text-sm'>Public ID</label>
                        <input
                          readOnly={true}
                          name="enablingTicket-publicId"
                          className={`w-full rounded px-2 py-1 bg-grayLighter outline-none placeholder-gray`}
                          type="text"
                          placeholder="empty"
                          value={ticket.publicId || ''}
                        />
                      </span>

                      <span className='inline-block w-full p-2 pt-0'>
                        <div className="flex items-end justify-end">
                          <Button size="small" className="w-fit"
                            to={`/admin/all/edit/Ticket/${ticket._id}`}
                            color="white">
                            View ticket
                          </Button>
                        </div>
                      </span>
                    </ div>
                  )) : <p>No enabling tickets</p>
                }
              </Section>
            }

            {
              data && data.stored &&
              <Section title="Raw ticket info" open={visibleSections.includes('rawTicket')} onClick={() => handleVisibleSections('rawTicket')}>
                <textarea
                  disabled
                  name="query"
                  className={`w-full h-60 px-2 py-1 rounded bg-grayLight outline-none resize-y`}
                  placeholder={'Query'}
                  value={data.rawTicket && JSON.stringify(data.rawTicket, undefined, 4) || ''}
                />
              </Section>
            }

            {
              data && data.stored &&
              <Section title="Entrances" open={visibleSections.includes('entrances')} onClick={() => handleVisibleSections('entrances')}>
                {entrances && entrances.length > 0 &&
                  <table className='mb-2'>
                    <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
                      <tr>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Type</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Date (last entrance first)</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Zone</th>
                        <th className="relative px-4 py-2 text-left wght-semibold ">Control Device Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {entrances.map(entrance => <tr key={entrance._id} className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight">
                        <td className={`text-white text-center px-4 py-2 whitespace-nowrap ${entrance.direction === 1 ? 'bg-success' : entrance.direction === 0 ? 'bg-warning' : 'bg-error'}`}>
                          {entrance.direction === 1 ? 'IN' : entrance.direction === 0 ? 'DUPLICATED' : 'OUT'}
                        </td>
                        <td className="px-4 py-2 whitespace-nowrap">{dayjs(entrance.createdAt, DEFAULT_TIMEZONE).format('DD-MM-YYYY HH:mm:ss')}</td>
                        <td className="px-4 py-2 whitespace-nowrap">{entrance.zone}</td>
                        <td className="px-4 py-2 whitespace-nowrap">{entrance.deviceName}</td>
                      </tr>
                      )}
                    </tbody>
                  </table>
                }

                {(!entrances || hasMoreEntrances) && <Button size="small" color="white" onClick={loadEntrances}>{entrances.length === 0 ? 'Load entrances' : 'Load more entrances'}</Button>}
                {(entrances.length === 0) && <p>No entrances</p>}
              </Section>
            }

            {
              data?.history && data.history.length > 0 &&
              <Section title="History" open={visibleSections.includes('history')} onClick={() => handleVisibleSections('history')}>
                <div className='relative p-2'>
                  <input
                    disabled={readMode}
                    className='mr-1 cursor-pointer'
                    type="checkbox"
                    id="showHistoryViews"
                    name="historyViews"
                    checked={showHistoryViews}
                    onChange={(e) => setShowHistoryViews(!showHistoryViews)}
                  />
                  <label className='mr-2 cursor-pointer select-none' htmlFor="showHistoryViews">Show history views</label>
                </div>
                {data && data.history && data.history.map((history, index) => {
                  return <div
                    key={`${index}.${history.date}`}
                    className={`bg-grayLighter p-1 mb-2 mx-1 text-xs border border-solid border-grayLight
                  ${history.type === 'manager' && history.action === 'view' && !showHistoryViews ? 'hidden' : ''}
                  `}
                  >
                    <div className="flex justify-between">
                      <span>
                        <span className="px-1 mr-2 border-1 border-gray" style={{ backgroundColor: colorFromString(`${history.type}`) }}>{history.type}</span>
                        → &nbsp;
                        <span className="px-1 mr-2 border-1 border-gray" style={{ backgroundColor: colorFromString(`${history.action}`) }}>{history.action}</span>
                      </span>
                      <span className="text-gray">{dayjs(history.date).format('DD/MM/YYYY HH:mm:ss')}</span>
                    </div>
                    <div className="p-2 mt-1 bg-grayLight ">
                      {history.data && Object.keys(history.data).length > 0 &&
                        Object.keys(history.data).map(key => {
                          switch (true) {
                            case key === 'pttuser' || key === 'pttuserid':
                              return <></>
                            case history.type === 'manager' && key === 'user':
                              return <span key={key} className="block">
                                <span className="wght-bold">User manager</span>: <Link className="hover:text-primary" to={`/admin/${event}/edit/PttUser/${history.data['pttuserid']}`}>{history.data['pttuser']}</Link>
                              </span>
                            case history.type === 'manager' && history.action === 'edit-wristband' && key === 'old_data':
                              const wristbandInfo = Object.keys(history.data[key]).filter(k => (
                                // Check if the value is different
                                history.data[key][k] !== history.data[k]
                              ) && !(
                                // Check if the value is null and the new value is empty
                                !history.data[key][k] && !history.data[k]
                              ))
                              if (wristbandInfo.length === 0) return <></>
                              return <div>
                                <p className="wght-bold">Modified data:</p>
                                <table key={key} className='block w-full'>
                                  <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
                                    <tr>
                                      <th className="relative px-2 py-1 text-left wght-semibold ">Field</th>
                                      <th className="relative px-2 py-1 text-left wght-semibold ">Original</th>
                                      <th className="relative px-2 py-1 text-left wght-semibold ">Modified</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {wristbandInfo.map((d) => <tr key={d} className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight">
                                      <td className="px-2 py-1 whitespace-nowrap">{d}</td>
                                      <td className="px-2 py-1 whitespace-nowrap">{history.data[key][d] || "null"}</td>
                                      <td className="px-2 py-1 whitespace-nowrap">{history.data[d] || "null"}</td>
                                    </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            case history.type === 'manager' && key === 'modifiedData':
                              const rawData = history.data[key].filter(i => (i && (Object.keys(i).length > 0) && i.key))
                              return <div>
                                <p className="wght-bold">Modified data:</p>
                                <table key={key} className='block w-full'>
                                  <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
                                    <tr>
                                      <th className="relative px-2 py-1 text-left wght-semibold ">Field</th>
                                      <th className="relative px-2 py-1 text-left wght-semibold ">Original</th>
                                      <th className="relative w-full px-2 py-1 text-left wght-semibold">Modified</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {rawData.map((d) => {
                                      if (d.key) {
                                        return <tr key={d.key} className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight">
                                          <td className="px-2 py-1 whitespace-nowrap">{d.key}</td>
                                          <td className="px-2 py-1 whitespace-nowrap">{JSON.stringify(d.original)}</td>
                                          <td className="px-2 py-1 whitespace-nowrap">{JSON.stringify(d.modified)}</td>
                                        </tr>
                                      }
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            case history.action === 'edit-wristband':
                              return <></>
                            default:
                              return <span key={key} className="block">
                                <span className="wght-bold">{key}</span>: {JSON.stringify(history.data[key])}
                              </span>
                          }
                        }
                        )}
                    </div>
                  </div>
                })}
              </Section>
            }
          </div >

          {data && data.stored && data.stored.deleted ? '' :
            <div className="sticky bottom-0 z-30 flex justify-between p-4 text-xs bg-white border-t border-grayLight">
              <div>
                {saving ? null : (
                  <Link
                    to={`/admin/${event}/list/Ticket`}
                    className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-error"
                  >
                    Cancel (back)
                  </Link>
                )}
              </div>

              <div className='flex items-center'>
                {wristBandCodeError &&
                  <p className="pr-2 text-error">Wristband code already exists</p>
                }

                {saving && !loading && !loadingFields && (
                  <span className="text-black wght-bold">{id ? 'Updating...' : 'Creating...'}</span>
                )}

                {!saving && !loading && !loadingFields && role !== 'read' && (
                  <span
                    onClick={() => save()}
                    className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                  >
                    {id ? 'Update' : 'Create'} &#8599;{' '}
                  </span>
                )}
                {loading || loadingFields && <span className="ml-4 text-black">Loading data...</span>}
                {saved && <span className="ml-4 text-black">Done!</span>}
                {modelName === 'Ticket' && notUniqueId && <span className="pl-2 text-red">Ticket ID must be unique</span>}
              </div>
            </div>
          }
        </div >
      )
      }
    </div >
  )
}

export default EditTickets
