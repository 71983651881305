import React, { useState, useEffect } from 'react'
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import useLocalStorage from '../hooks/useLocalStorage'
import { usePrevRoute } from './Store'
import InvitationModal from './InvitationModal'
import dayjs from 'dayjs'
import ImportExport from './ImportExport'
import { useSession } from '../contexts/AuthContext'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import Button from './Button'

const ListAppLiteral = ({ modelName, setUpdateEvents, setUpdateOrganizations }) => {
  const history = useHistory()
  const { page: pageParam, event } = useParams()
  const { getOrganization, getEvent } = useOrganizationInfo(event)
  const [data, setData] = useState(false)
  const [userSortSettings, setUserSortSettings] = useLocalStorage('user_sort_settings', {})
  const [sortedValue, setSortedValue] = useState()
  const [sortAscending, setSortAscending] = useState(true)
  const [sortedItems, setSortedItems] = useState()
  const [title, setTitle] = useState(modelName)
  const [page, setPage] = useState(pageParam - 1 || false)
  const [hasMorePages, setHasMorePages] = useState(false)
  const pageLimit = 5000
  const [duplicatedId, setDuplicatedId] = useState(false)
  const [loading, setLoading] = useState(true)
  const route = usePrevRoute()
  const [forbiddenModels, setForbiddenModels] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [role, setRole] = useState(false)
  const [literals, setLiterals] = useState(null)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [literalsToUpdate, setLiteralsToUpdate] = useState([])
  const token = useSession()

  const [filters] = useState({
    organization: getOrganization(),
    event: getEvent(),
  })

  useEffect(() => {
    setShowModal(false)
  }, [history.location.pathname])

  useEffect(() => {
    let prevRoute = false
    if (route) {
      prevRoute = route.split('/')
      prevRoute = prevRoute[3]
    }
    if (prevRoute && (prevRoute !== modelName)) {
      setPage(0)
    }
  }, [modelName])

  useEffect(() => {
    setLoading(true)
    getData()

    const urlParts = history.location.pathname.split(modelName)

    if (urlParts[0].includes('no-access')) {
      return 
    }

    if (page) {
      const updatePageUrl = `${urlParts[0]}${modelName}/${page + 1}`
      history.replace({ pathname: updatePageUrl })
    } else {
      const updatePageUrl = `${urlParts[0]}${modelName}`
      history.replace({ pathname: updatePageUrl })
    }
  }, [page, modelName, duplicatedId])

  useEffect(() => {
    const settings = { ...userSortSettings }
    if (settings.hasOwnProperty(modelName)) {
      const { value, ascending } = settings[modelName]
      setSortedValue(value)
      setSortAscending(ascending)
    }
    setUserSortSettings(settings)
  }, [modelName])

  useEffect(() => {
    const settings = { ...userSortSettings }
    if (typeof sortedValue === 'undefined' || typeof sortAscending === 'undefined') {
      delete settings[modelName]
    } else {
      settings[modelName] = {
        value: sortedValue,
        ascending: sortAscending,
      }
    }
    setUserSortSettings(settings)
  }, [sortedValue, sortAscending])

  useEffect(() => {
    if (typeof data === 'object' && typeof data.items !== 'undefined') {
      const items = [...data.items]
      if (typeof sortedValue !== 'undefined') {
        const sorted = items.sort((a, b) => {
          const itemA = typeof a[sortedValue] == 'string' ? a[sortedValue].toLowerCase() : undefined
          const itemB = typeof b[sortedValue] == 'string' ? b[sortedValue].toLowerCase() : undefined

          if (typeof itemA === 'undefined' && typeof itemB === 'undefined') return 0
          if (typeof itemA === 'undefined') return sortAscending ? 1 : -1
          if (typeof itemB === 'undefined') return sortAscending ? -1 : 1

          if (itemA < itemB) return sortAscending ? -1 : 1
          if (itemA > itemB) return sortAscending ? 1 : -1
          return 0
        })
        setSortedItems(sorted)
      } else {
        setSortedItems(data.items)
      }
    }
  }, [data, sortedValue, sortAscending, modelName])

  useEffect(() => {
    if (sortedItems) setLiterals(sortedItems)
  }, [sortedItems])

  const getData = () => {
    setData(false)

    fetch(`${process.env.REACT_APP_API_URL}model-list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        organization: getOrganization(),
        event: getEvent(),
        modelName,
        page,
        pageLimit,
      }),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)
          setTitle(`List ${response.body.modelConfig.title}`)
          const rawBody = { ...response.body }
          setForbiddenModels(rawBody.forbiddenModels)
          Object.keys(rawBody.modelConfig.list).map(key => {
            if (typeof rawBody.modelConfig.list[key] === 'object') {
              rawBody.modelConfig.list[key] = rawBody.modelConfig.list[key].label
            }
          })

          //reorder text
          if (rawBody.items) {
            Object.keys(rawBody.items).map(key => {
              rawBody.items[key].text = {
                en: rawBody.items[key].text['en'],
                es: rawBody.items[key].text['es'],
                ca: rawBody.items[key].text['ca'],
              }
            })
          }

          setData(rawBody)
          setSortAscending(Object.values(rawBody.modelConfig.sort)[0] === 'asc' ? true : false)
          setSortedValue(Object.keys(rawBody.modelConfig.sort)[0])
          if (response.body.pageLimit) {
            setHasMorePages(response.body.items.length === response.body.pageLimit)
          } else {
            setHasMorePages(response.body.items.length === pageLimit)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const duplicate = id => {
    if (window.confirm('Duplicate?')) {
      fetch(`${process.env.REACT_APP_API_URL}model-duplicate`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          modelName,
          organization: getOrganization(),
          event: getEvent(),
          _id: id,
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            setDuplicatedId(response.body.duplicatedId)
            setUpdateEvents(true)
            setUpdateOrganizations(true)
          } else if (response.status === 400) {
            alert('Error ' + response.body.error)
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('user')
              history.push('/login')
            } else if (response.status === 401) {
              history.push(`/admin/no-access?url=${window.location.pathname}`)
            }
          }
        })
    }
  }

  const remove = id => {
    if (window.confirm('Delete?')) {
      fetch(`${process.env.REACT_APP_API_URL}model-delete`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          modelName,
          _id: id,
          organization: getOrganization(),
          event: getEvent(),
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            if (modelName === 'Event') {
              setUpdateEvents(id)
            }
            if (modelName === 'Organization') {
              setUpdateOrganizations(id)
            }
            data.items = data.items.filter(item => item._id !== id)
            setData({ ...data })
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('user')
              history.push('/login')
            } else if (response.status === 401) {
              history.push(`/admin/no-access?url=${window.location.pathname}`)
            }
          }
        })
    }
  }

  const handleSort = key => {
    if (sortedValue === key) {
      if (sortAscending) {
        setSortAscending(false)
      } else {
        setSortedValue(undefined)
        setSortAscending(undefined)
      }
    } else {
      setSortedValue(key)
      setSortAscending(true)
    }
  }

  const handleShowModal = () => setShowModal(state => !state)

  const updateInfo = async (id, index) => {
    setUpdateLoading(true)
    const bodyData = {
      modelName,
      filters,
    }

    if (id) {
      bodyData._id = id
    }

    if (literals) {
      bodyData.text = literals[index].text
      bodyData.reference = literals[index].reference
    }

    fetch(`${process.env.REACT_APP_API_URL}model-update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setUpdateLoading(false)
          setLiteralsToUpdate([])
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
      .catch((err) => {
        setUpdateLoading(false)
        setLiteralsToUpdate([])
      })
  }

  const Actions = ({ id, modelName, index }) => {
    return (
      <div className="relative flex items-center text-xs">
        {role !== 'read' &&
          <span className="block mr-4 cursor-pointer hover:text-primary hover:wght-semibold " onClick={() => updateInfo(id, index)}>Update</span>
        }

        {role !== 'read' &&
          <span className="block mr-4 cursor-pointer hover:text-primary hover:wght-semibold" onClick={() => duplicate(id)}>Clone</span>
        }

        {role !== 'read' && !forbiddenModels.includes(modelName) &&
          <span className="block cursor-pointer hover:text-primary hover:wght-semibold" onClick={() => remove(id)}>Delete</span>
        }
      </div>
    )
  }

  const handleUpdateLiteral = (e, index, language) => {
    const newLiterlas = {
      ...literals,
    }

    newLiterlas[index].text[language] = e.target.value

    if (!literalsToUpdate.includes(index)) {
      setLiteralsToUpdate(literals => [...literals, index])
    }

    setLiterals(newLiterlas)
  }

  const handleUpdateReference = (e, index) => {
    const newLiterlas = {
      ...literals,
    }

    newLiterlas[index].reference = e.target.value

    setLiterals(newLiterlas)
  }

  const handleExport = () => {
    const bodyData = {
      modelName,
      currentOrganization: filters.organization,
      currentEvent: filters.event,
    }

    fetch(`${process.env.REACT_APP_API_URL}export-appliterals`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          const csv = response?.body?.body
          if (csv) {
            const blob = new Blob([csv], { type: 'text/csv' })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            const today = dayjs().format('YYYY-MM-DD_HH-mm-ss')
            const fileName = `literals-${today}.csv`
            link.setAttribute('href', url)
            link.setAttribute('download', `${fileName}`)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }

        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const handleImport = () => {
    setUploading(true)
    const file = document.querySelector('input[type=file]').files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append('modelName', modelName)
    formData.append('currentOrganization', getOrganization())
    formData.append('currentEvent', getEvent())

    fetch(`${process.env.REACT_APP_API_URL}import-appliterals`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setUploading(false)
        if (response.status === 200) {
          // refresh page
          window.location.reload()
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      }
      )
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex'>
        <div className="relative flex grow-1">
          <h2 className="text-xl wght-semibold">{title}</h2>
          {showModal &&
            <InvitationModal token={token} handleShowModal={handleShowModal} modelName={modelName} event={event} role={role}/>
          }
        </div>

        <div className="relative flex justify-between grow-0">
          <div className="relative flex items-center justify-center">
            <Button size="small" onClick={() => handleShowModal()} color='success' visibility={role === 'admin' && modelName !== 'Event' || false}
            >Share</Button>
            <Button visibility={role !== 'read'} size="small">
              <Link to={`/admin/${event}/edit/${modelName}`}>Add</Link>
            </Button>
          </div>
        </div>
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }

      <div className="relative flex items-center justify-start mt-2">
        {role !== 'read' && !loading && (
          <ImportExport
            uploading={uploading}
            handleImport={handleImport}
            handleExport={handleExport}
          />
        )}
      </div>

      {data && data.items.length === 0 && <div className='mt-8'>Nothing to list</div>}

      {data && data.items.length > 0 && sortedItems &&
        <div className="mt-2 overflow-scroll">
          <table className="w-full">
            <thead className="w-full text-left bg-white border-b border-grayLight">
              <tr>
                {Object.keys(data.modelConfig.list).map(key => {
                  if (key === '_id') return
                  if (typeof data.modelConfig.list[key] === 'undefined') return
                  return (
                    <th key={key} className="px-4 py-2">
                      <span
                        className="relative cursor-pointer wght-semibold"
                        onClick={() => handleSort(key)}
                      >
                        {typeof data.modelConfig.list[key] === 'string' && data.modelConfig.list[key]}
                        {typeof data.modelConfig.list[key] === 'object' && data.modelConfig.list[key].label}
                        {sortedValue === key && (
                          <span className="absolute inset-y-0 right-0 flex items-center justify-center -mr-4 text-center">
                            {sortAscending ? "↑" : "↓"}
                          </span>
                        )}
                      </span>
                    </th>
                  )
                })}
                <th className="px-4 py-2 text-right wght-semibold">Actions</th>
              </tr>
            </thead>

            <tbody>
              {sortedItems.map((item, index) =>
                <tr
                  key={item._id}
                  className={`align-top relative bg-white border-b border-grayLight hover:bg-primaryLight ${literalsToUpdate.includes(index) ? 'bg-primaryLight' : ''} ${modelName === 'ticket' ? 'text-sm' : ''}
                  `}
                >
                  {Object.keys(data.modelConfig.list).map(key => {
                    if (key === '_id') return
                    if (typeof data.modelConfig.list[key] === 'undefined') return

                    return (
                      <td key={key} className="px-4 py-2">
                        {
                          typeof item[key] !== 'undefined' && typeof item[key] === 'object' && key === 'text' && literals !== null && (
                            Object.keys(item[key]).map((itemKey, indexKey) =>
                              <div
                                key={itemKey}
                                className="flex items-center justify-center"
                              >
                                <div className={`text-xs mr-2  py-1 ${indexKey && 'mt-1'} wght-semibold`}>{itemKey.toLocaleUpperCase()}</div>
                                <input
                                  readOnly={role === 'read'}
                                  name="text"
                                  className={`rounded px-2 bg-grayLight outline-none placeholder-gray w-full py-1 ${indexKey && 'mt-1'} text-xs`}
                                  type="text"
                                  value={
                                    literals === null ?
                                      item[key][itemKey]
                                      : literals[index]?.text[itemKey]}
                                  onChange={(e) => handleUpdateLiteral(e, index, itemKey)}
                                />
                              </div>
                            )
                          )
                        }
                        {
                          typeof item[key] !== 'undefined' && typeof item[key] === 'string' && key === 'reference' && literals !== null &&

                          <>
                            <input
                              readOnly={role === 'read'}
                              name="reference"
                              className={`rounded px-2 bg-grayLight outline-none placeholder-gray w-full py-1 text-xs`}
                              type="text"
                              value={
                                literals === null ?
                                  item[key]
                                  : literals[index]?.reference}
                              onChange={(e) => handleUpdateReference(e, index)}
                            />
                          </>
                        }

                      </td>
                    )
                  })}

                  <td className="table-cell px-4 py-2 text-right">
                    <div className="flex justify-end">
                      {!updateLoading &&
                        <Actions modelName={modelName} id={item._id} code={item.code} publicId={item.publicId} index={index} />
                      }
                      {updateLoading &&
                        <span className='px-2 py-1 mr-2 text-xs'>Updating...</span>
                      }
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      }

      {loading && <div className="mt-8">Loading...</div>}

      {
        !loading && (hasMorePages || page > 0) &&
        <div className="flex justify-between mt-8">
          <div>
            {page > 0 && <span onClick={() => setPage(page - 1)} className="ml-1 cursor-pointer hover:wght-semibold">Previous page</span>}
          </div>
          {hasMorePages &&
            <span onClick={() => setPage(page + 1)} className="mr-1 cursor-pointer hover:wght-semibold">Next page</span>
          }
        </div>
      }
    </>
  )
}

export default ListAppLiteral