import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import Cell from "../Cell"
import QRCode from 'qrcode.react'
import ModalSendMessage from '../ModalSendMessage'
import ModalSendBatchMessages from '../ModalSendBatchMessages'
import Select from 'react-select';
import Button from '../Button'
import InvitationModal from './../InvitationModal'
import { useSession } from '../../contexts/AuthContext'
import NothingToList from "../NothingToList"
import Pagination from "../Pagination"
import Loader from "../Loader"
import useOrganizationInfo from '../../hooks/useOrganizationInfo'
import qr from '../Lib/qr'

const Tickets = ({ organizationEvent, developer }) => {
  const { page: pageParam, event } = useParams()
  const { getOrganization, getEvent, organizationEvents: events } = useOrganizationInfo(event)
  const modelName = 'Ticket'
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [data, setData] = useState(false)
  const [page, setPage] = useState(pageParam - 1 || 0)
  const pageLimit = 50
  const [hasMorePages, setHasMorePages] = useState(false)
  const [ticketTypes, setTicketTypes] = useState([])
  const [showQR, setShowQR] = useState(null)
  const [showQRTickedId, setShowQRTickedId] = useState(null)
  const filtersForm = useRef(null)
  const [notificationText, setNotificationText] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [showModalMessage, setShowModalMessage] = useState(null)
  const [modalMessageParams, setModalMessageParams] = useState([])
  const search = useLocation().search;
  const [count, setCount] = useState(false)
  const [countEmail, setCountEmail] = useState(false)
  const [showCountEmail, setShowCountEmail] = useState(false)
  const [loadingCountEmail, setLoadingCountEmail] = useState(false)
  const [showCount, setShowCount] = useState(false)
  const [loadingCount, setLoadingCount] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [multipleSelect, setMultipleSelect] = useState(false)
  const externalFilters = ['event', 'organization']
  const [batchTickets, setBatchTickets] = useState([])
  const [showSendBatchMessagesModal, setShowSendBatchMessagesModal] = useState(false)
  const [filterTemplates, setFilterTemplates] = useState([])
  const [selectedFilterTemplate, setSelectedFilterTemplate] = useState()
  const [role, setRole] = useState(false)
  const token = useSession()

  const defaultUserManagerPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))

  const [showFilters, setShowFilters] = useState(defaultUserManagerPreferences && defaultUserManagerPreferences.ticketsShowFilters || true)
  const [showFilterTemplates, setShowFilterTemplates] = useState(false)
  const [showColumnsFilters, setShowColumnsFilters] = useState(defaultUserManagerPreferences && defaultUserManagerPreferences.ticketsShowColumnsFilters || false)
  const hasSearchParams = window.location.search
  const [filters, setFilters] = useState((!hasSearchParams && event) && defaultUserManagerPreferences?.ticketsFilters || {})
  const [tagFilters, setTagFilters] = useState((!hasSearchParams && event) && defaultUserManagerPreferences?.ticketsFiltersTags || {})
  const [tagsToFilter, setTagsToFilter] = useState((!hasSearchParams && event) && defaultUserManagerPreferences?.ticketsFiltersTags || {})
  const [showQuery, setShowQuery] = useState(defaultUserManagerPreferences && defaultUserManagerPreferences.ticketsShowQuery || false)

  const [visibleColumns, setVisibleColumns] = useState(
    defaultUserManagerPreferences && defaultUserManagerPreferences.ticketsVisibleColumns
    || [
      'event',
      'ticketId',
      'ticketType',
      'name',
      'info',
      'guestsInvitationName',
      'userEmail',
      'email',
      'purchaseEmail',
      'guestsInvitationEmail',
      'status',
      'phone',
      'hasBeenUsedDate'
    ])

  useEffect(() => {
    const params = {}
    const rawParams = search.replace('?', '').split('&').map(i => params[i.split('=')[0]] = i.split('=')[1])
    if (rawParams) {
      setTimeout(() => {
        updateUrlParams(params)
      }, 500);
    }
  }, [])

  useEffect(() => {
    if (loading && !firstLoad) {
      getData()
    }
  }, [loading])

  useEffect(() => {
    if (!loading && !firstLoad) {
      setLoading(true)
    }
  }, [history.location.pathname])

  useEffect(() => {
    if (showCountEmail) {
      getCountEmail()
    }
  }, [showCountEmail])

  useEffect(() => {
    if (!firstLoad) {
      setLoading(true)
    }

    const urlParts = history.location.pathname.split(modelName)
    let urlPage = ''
    if (page || window.location.search) urlPage = `/${page + 1}`

    const updatePageUrl = `${urlParts[0]}${modelName}${urlPage}${window.location.search}`
    history.replace({ pathname: updatePageUrl })
  }, [page])

  const getCount = (body) => {
    setLoadingCount(true)
    fetch(`${process.env.REACT_APP_API_URL}tickets`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...body,
        showCount,
      }),
    })
      .then((response) => response.json().then((data) => ({ status: response.status, body: data })))
      .then((response) => {
        setLoadingCount(false)
        if (response.status === 401) {
          history.push(`/admin/no-access?url=${window.location.pathname}`)
        } else if (response.status === 200) {
          setCount(response.body.count)
        }
      })
  }

  const getCountEmail = () => {
    const rawFilters = filters
    rawFilters.event = getEvent()
    rawFilters.organization = getOrganization()
    const body = {
      modelName,
      page,
      pageLimit,
      filters: rawFilters,
      showQuery,
      showCountEmail: true,
    }

    setLoadingCountEmail(true)
    fetch(`${process.env.REACT_APP_API_URL}tickets`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json().then((data) => ({ status: response.status, body: data })))
      .then((response) => {
        setLoadingCountEmail(false)
        if (response.status === 401) {
          history.push(`/admin/no-access?url=${window.location.pathname}`)
        } else if (response.status === 200) {
          setCountEmail(response.body.countEmail)
        }
      })
  }

  const getData = () => {
    const rawFilters = filters
    rawFilters.event = getEvent()
    rawFilters.organization = getOrganization()
    const body = {
      modelName,
      page,
      pageLimit,
      filters: rawFilters,
      showQuery,
    }

    if (showCount) {
      getCount(body)
    }

    fetch(`${process.env.REACT_APP_API_URL}tickets`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json().then((data) => ({ status: response.status, body: data })))
      .then((response) => {
        setLoading(false)
        if (response.status === 401) {
          history.push(`/admin/no-access?url=${window.location.pathname}`)
        } else if (response.status === 200) {
          setRole(response.body.role)
          setData(response.body)
          setTicketTypes(response.body.ticketTypes.map((t, i) => ({ value: t._id, label: t.title, position: i })))
          const segments = response.body.filterTemplates.map((t) => ({ value: t._id, label: t.title, templateFilters: t.templateFilters }))
          if (selectedFilterTemplate && !selectedFilterTemplate.value && segments.length > 0) {
            const selectedTemplate = segments.find((t) => t.label === selectedFilterTemplate.label)
            if (selectedTemplate) {
              setSelectedFilterTemplate(selectedTemplate)
              setFilters(selectedTemplate.templateFilters)
              setTagsToFilter(selectedTemplate.templateFilters)
              setLoading(true)
            }
          }
          setFilterTemplates(segments)
          // setCount(response.body.count)
          // setCountEmail(response.body.countEmail)
          if (response.body && response.body.tickets) {
            setHasMorePages(response.body.tickets.length === pageLimit)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          setData()
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          }
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        setData()
      })
  }

  useEffect(() => {
    if (showCount && !loading && !firstLoad) {
      setLoading(true)
    }
  }, [showCount])

  const clearFilters = () => {
    const rawFilters = { ...filters }
    Object.keys(rawFilters).forEach(f => {
      if (!externalFilters.includes(f)) {
        delete rawFilters[f]
      }
    });
    setFilters({ ...rawFilters })
    setTagFilters({ ...rawFilters })
    setTagsToFilter({ ...rawFilters })

    filtersForm.current.reset()
  }

  const filterTickets = () => {
    const rawTagFilters = { ...filters }
    setTagFilters(rawTagFilters)
  }

  const handleFilters = (filterName, value) => {
    if (value && typeof value === 'string' && value.includes('acst://t/')) {
      value = value.replace('acst://t/', '')
    }

    let rawFilters = { ...filters, [filterName]: value }

    if (filterName === 'validKyc' && value === 'nokyc') {
      rawFilters.exchanged = 'exchanged'
    }

    if (filterName === "filterTemplate") {
      const findSelectedFilterTemplate = filterTemplates.find(template => template.value === value)
      rawFilters = { ...findSelectedFilterTemplate.templateFilters, [filterName]: value }
    }

    setFilters(rawFilters)
    setTagsToFilter(rawFilters)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      filterTickets()
    }
  }

  const handleRemoveFilter = (type) => {
    const rawFilters = filters
    delete rawFilters[type]
    setFilters({ ...rawFilters })
    setTagFilters({ ...rawFilters })
    setTagsToFilter({ ...rawFilters })
    const input = document.querySelector(`.ticketFilters input[name="${type}"]`)
    if (input) {
      input.value = null
    }
  }

  const updateUrlParams = (tags) => {
    if (firstLoad) {
      const rawFilters = { ...filters }
      Object.keys(tags).forEach(tag => {
        rawFilters[tag] = tags[tag]
      })
      if (rawFilters.segment) {
        setSelectedFilterTemplate({
          label: rawFilters.segment.replace(/%20/g, ' '),
          value: false,
        })
      }
      setFilters(rawFilters)
      setTagFilters({ ...rawFilters })
      setTagsToFilter({ ...rawFilters })
      setFirstLoad(false)
      setTimeout(() => {
        setLoading(true)
      }, 100);
    } else {
      let params = ''
      Object.keys(tags).filter(i => !externalFilters.includes(i) && tags[i]).forEach((key, index) => {
        if (index === 0) {
          params = `?${key}=${tags[key]}`
        } else {
          params = `${params}&${key}=${tags[key]}`
        }
      });
      if (!params) {
        window.history.pushState({}, "", window.location.href.replace(window.location.search, ''));
      } else {
        window.history.pushState({}, "", params);
      }
      setLoading(true)
    }
  }

  useEffect(() => {
    if (!firstLoad) {
      setPage(0)
      updateUrlParams(tagFilters)
    }
  }, [tagFilters])

  const handleCopyText = (field, text) => {
    navigator.clipboard.writeText(text)
    setNotificationText(`Copied ${field}: ${text}`)
  }

  useEffect(() => {
    if (notificationText) {
      setTimeout(() => {
        setNotificationText('')
      }, 3000);
    }
  }, [notificationText])

  const handleSendMessage = (params) => {
    setModalMessageParams(params)
    setShowModalMessage(true)
  }

  const handleVisibleColumns = (column) => {
    let rawColumns = [...visibleColumns]
    if (rawColumns.includes(column)) {
      rawColumns = rawColumns.filter(col => col !== column)
    } else {
      rawColumns.push(column)
    }
    setVisibleColumns([...rawColumns])
  }

  useEffect(() => {
    let userManagerPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))
    if (!userManagerPreferences) {
      userManagerPreferences = {}
    }

    userManagerPreferences.ticketsVisibleColumns = [...visibleColumns]
    userManagerPreferences.ticketsShowFilters = showFilters
    userManagerPreferences.ticketsShowColumnsFilters = showColumnsFilters
    userManagerPreferences.ticketsShowQuery = showQuery
    userManagerPreferences.ticketsFilters = filters
    userManagerPreferences.ticketsFiltersTags = tagFilters

    Cookies.set('userManagerPreferences', JSON.stringify(userManagerPreferences), { expires: 365 })
  }, [visibleColumns, showFilters, showColumnsFilters, filters, tagFilters, showQuery])


  const columnsData = [
    { key: 'event', label: 'Event' },
    { key: 'ticketId', label: 'Ticket ID' },
    { key: 'ticketIdOriginal', label: 'Original Ticket ID' },
    { key: 'orderIdOriginal', label: 'Original Order ID' },
    { key: 'deviceId', label: 'Device ID' },
    { key: 'ticketType', label: 'Type' },
    { key: 'status', label: 'Status' },
    { key: 'name', label: 'Name' },
    { key: 'info', label: 'Name (KYC)' },
    { key: 'hasBeenUsedDate', label: 'Entrance at' },
    { key: 'userEmail', label: 'User email' },
    { key: 'email', label: 'Ticket email' },
    { key: 'purchaseEmail', label: 'Purchase email' },
    { key: 'guestsInvitationEmail', label: 'Guests email' },
    { key: 'requirements', label: 'Requirements' },
    { key: 'phone', label: 'Phone' },
    { key: 'pointOfSale', label: 'pointOfSale' },
    { key: 'importBatchId', label: 'importBatchId' },
    { key: 'importOriginSource', label: 'importOriginSource' },
    { key: 'validKyc', label: 'KYC Rejection' },
    { key: 'age', label: 'Minor/Adult' },
    { key: 'ticketLanguage', label: 'Ticket Language' },
    { key: 'userLanguage', label: 'User Language' },
    { key: 'exchangeDate', label: 'Exchange date' },
  ]

  const ColumnFilter = ({ filterKey, label }) => {
    return (
      <span>
        <input
          disabled={loading}
          className={`mr-1 cursor-pointer ${visibleColumns.includes(filterKey) ? "accent-orange" : ""}`}
          type="checkbox"
          id={`column-${filterKey}`} name={`column-${filterKey}`}
          value=""
          onChange={() => handleVisibleColumns(filterKey)}
          checked={visibleColumns.includes(filterKey)}
        />
        <label className='mr-4 cursor-pointer select-none md:mr-2' htmlFor={`column-${filterKey}`}>{label}</label>
      </span>
    )
  }

  const Column = ({ filterKey, label }) => {
    if (!visibleColumns.includes(filterKey)) return <></>

    return (<th className="px-4 py-2 border-b-2 border-grayLight ">
      <span className="relative cursor-pointer wght-semibold">{label}</span>
    </th>)
  }

  const batchSelector = (value) => {
    let rawBatchTickets = [...batchTickets]
    if (rawBatchTickets.includes(value)) {
      rawBatchTickets = rawBatchTickets.filter(i => i._id !== value._id)
    } else {
      rawBatchTickets.push(value)
    }
    setBatchTickets([...rawBatchTickets])
  }

  const openSendBatchMessagesModal = () => {
    setShowSendBatchMessagesModal(true)
  }

  const handleShowModal = () => setShowModal(state => !state)

  const saveFilterTemplate = async (e) => {
    e.preventDefault()
    setUpdating(true)
    const { organization, segment } = filters

    if (!segment) return alert("Template name is required")

    fetch(`${process.env.REACT_APP_API_URL}model-update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        _id: filters.filterTemplate || null,
        modelName: "FilterTemplates",
        relatedModelName: modelName,
        title: segment,
        currentOrganization: organization,
        filters,
        templateFilters: {
          ...filters,
          event: false
        }
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setUpdating(false)

        if (response.status === 200) {
          const { _id, title, templateFilters } = response.body
          const newTemplate = {
            value: _id,
            label: title,
            templateFilters
          }
          setFilterTemplates(state => [...state, newTemplate])
          setFilters(state => { return { ...state, filterTemplate: _id } })
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const removeFilterTemplate = (e) => {
    e.preventDefault()
    setLoading(true)

    if (window.confirm('Are you sure you want to delete this template? This action cannot be undone and other users will not be able to use it.')) {
      fetch(`${process.env.REACT_APP_API_URL}model-delete`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          modelName: "FilterTemplates",
          relatedModelName: modelName,
          _id: filters.filterTemplate,
          filters,
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          setLoading(false)

          if (response.status === 200) {
            const templateRemovedOfFilterTemplates = filterTemplates.filter(template => template._id !== filters.filterTemplate)
            setFilterTemplates(templateRemovedOfFilterTemplates)
            setTagFilters(templateRemovedOfFilterTemplates)
            clearFilters()
          } else {
            clearFilters()
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('user')
              history.push('/login')
            } else if (response.status === 401) {
              history.push(`/admin/no-access?url=${window.location.pathname}`)
            }
          }
        }
        )
    }
  }

  return (
    <>
      <Helmet>
        <title>Tickets</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">Tickets</h2>
        {showModal &&
          <InvitationModal token={token} handleShowModal={handleShowModal} modelName={modelName} event={event} role={role}/>
        }
        <div className='flex'>
          <Button size="small" onClick={() => handleShowModal()} color='success' visibility={role === 'admin' && modelName !== 'Event' || false}
          >Share</Button>
          {/*TODO: block ticket creation on the BE as well */}
          <Button visibility={role === 'admin'} size="small" to={`/admin/${event}/edit/Ticket`}>
            Add
          </Button>
        </div>
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }

      <ModalSendMessage
        show={showModalMessage}
        showState={setShowModalMessage}
        data={modalMessageParams}
        event={getEvent()}
      />

      <ModalSendBatchMessages
        show={showSendBatchMessagesModal}
        showState={setShowSendBatchMessagesModal}
        count={countEmail}
        setShowCount={setShowCountEmail}
        event={getEvent()}
        filters={filters}
        filterTemplates={filterTemplates}
        selectedFilterTemplate={selectedFilterTemplate}
        developer={developer}
      />

      {notificationText &&
        <span className='fixed z-10 px-2 py-1 text-sm text-white rounded bottom-5 right-5 bg-primary'>{notificationText}</span>
      }

      <div className='flex flex-col justify-between mt-8 md:items-center md:flex-row'>
        <div className='flex w-full'>
          <Button color='white' onClick={() => setShowFilters(!showFilters)} className='mb-2 mr-2'>
            Filters {showFilters ? '↑' : '↓'}
          </Button>
          <Button color={selectedFilterTemplate ? 'invertedOrange' : 'white'} onClick={() => {
            setShowFilterTemplates(state => !state)
          }} className='mb-2 mr-2'>
            Segments {showFilterTemplates ? '↑' : '↓'}
          </Button>
          <Button color='white' onClick={() => setShowColumnsFilters(!showColumnsFilters)} className='mb-2 mr-2'>
            Columns {showColumnsFilters ? '↑' : '↓'}
          </Button>
          <Button color='white' onClick={() => setShowQuery(!showQuery)} className='mb-2 mr-2'>
            Show query {showQuery ? '↑' : '↓'}
          </Button>
        </div>
        <Button size="small" color={showCount ? "invertedGreen" : "white"} disable={loadingCount} className='mb-1 whitespace-nowrap' onClick={() => setShowCount(!showCount)}>
          {loadingCount && showCount && "Counting..."}
          {!loadingCount && showCount && `Tickets: ${count || 0}`}
          {!showCount && "Show tickets count"}
        </Button>
      </div>

      <form ref={filtersForm}>
        <div className={`p-2 mb-1 text-xs bg-white rounded ticketFilters ${showFilterTemplates ? '' : 'hidden'}`}>
          <div className="flex flex-col justify-between">
            <div className='flex flex-wrap items-end justify-end my-2 md:my-1'>
              <div className="flex flex-col items-start w-full md:flex-col" ref={filtersForm}>
                <div className='flex flex-col items-end w-full md:flex-row'>
                  <span className='inline-flex flex-col w-1/3 mx-1'>
                    <span>Segments (filter templates)</span>
                    <Select
                      isDisabled={loading}
                      isLoading={loading}
                      value={selectedFilterTemplate || false}
                      onChange={(selected) => {
                        setSelectedFilterTemplate(selected)
                        if (selected) return handleFilters("filterTemplate", selected?.value || false)
                        return clearFilters()
                      }}
                      options={filterTemplates}
                      className="text-xs"
                      isClearable={true}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#FFC4AD',
                          primary50: '#ffb293',
                          primary: '#0300c9',
                          neutral0: '#eee',
                        },
                        input: (styles) => ({ ...styles, borderColor: 'red' }),
                      })}
                      styles={{
                        indicatorSeparator: (styles) => ({ ...styles, backgroundColor: "#000" }),
                        loadingIndicator: (styles) => ({ ...styles, color: "#000", color: "#000" }),
                        placeholder: (styles) => ({ ...styles, color: "#000" }),
                        control: (styles) => ({
                          ...styles,
                          borderColor: "#eee",
                          minHeight: '20px',
                          backgroundColor: selectedFilterTemplate ? '#ffb293' : "#eee"
                        }),
                        valueContainer: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
                        input: (styles) => ({ ...styles, marginTop: 2, marginBottom: 1 }),
                        dropdownIndicator: (styles) => ({ ...styles, padding: 2, color: "#000" }),
                        clearIndicator: (styles) => ({ ...styles, padding: 2, color: "#000", color: "#000" }),
                        menuList: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
                      }}
                    />
                  </span>

                  <span className='inline-flex flex-col mx-1'>
                    <span>Edit segment title</span>
                    <input
                      disabled={loading}
                      name="segment"
                      className={`rounded border border-grayLight px-2 bg-grayLight outline-none placeholder-gray py-1`}
                      type="text"
                      placeholder="Template name"
                      value={filters?.segment?.replaceAll("%20", " ") || ""}
                      onChange={(e) => handleFilters('segment', e.target.value)}
                      onKeyDown={(e) => handleKeyDown(e)}
                    />
                  </span>

                  {filters?.filterTemplate
                    && <>
                      <Button size="small" color='cancel' disable={loading || updating} onClick={removeFilterTemplate} className="ml-1 mr-1">Delete</Button>
                      <Button size="small" color='white' disable={loading || updating} onClick={saveFilterTemplate} className="">{updating ? "Updating..." : "Update"}</Button>
                    </>
                  }
                  {!filters?.filterTemplate && <Button disable={loading || updating} onClick={saveFilterTemplate} className="ml-1 ">Create segment</Button>}
                </div>
              </div>
              <div>
                <Button disable={loading || updating} onClick={filterTickets}>{loading ? 'Loading...' : 'Filter'}</Button>
              </div>
            </div>
          </div>
        </div>

        <div className={`p-2 mb-1 text-xs bg-white rounded ticketFilters ${showFilters ? '' : 'hidden'}`}>
          <div className="flex flex-col justify-between">
            <div className="flex flex-col items-start w-full md:flex-col" ref={filtersForm}>
              <div className='flex flex-col w-full md:block md:border-grayLight md:border-b md:py-2 md:mb-2'>
                <span className='inline-flex flex-col mx-1 md:w-1/5'>
                  <span>Ticket type</span>
                  <Select
                    isDisabled={loading}
                    isLoading={loading}
                    value={false}
                    onChange={(selected) => {
                      handleFilters(`ticketType-${selected?.position}`, selected?.value || false)
                    }}
                    options={ticketTypes}
                    className="text-xs"
                    defaultValue={ticketTypes[0]}
                    isClearable={true}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#FFC4AD',
                        primary50: '#ffb293',
                        primary: '#0300c9',
                        neutral0: '#eee',
                      },
                      input: (styles) => ({ ...styles, borderColor: 'red' }),
                    })}
                    styles={{
                      indicatorSeparator: (styles) => ({ ...styles, backgroundColor: "#000" }),
                      loadingIndicator: (styles) => ({ ...styles, color: "#000" }),
                      placeholder: (styles) => ({ ...styles, color: "#000" }),
                      control: (styles) => ({
                        ...styles,
                        borderColor: "#eee",
                        minHeight: '20px',
                        backgroundColor: Object.keys(filters).some((key) => key.includes("ticketType")) ? '#ffb293' : "#eee"
                      }),
                      valueContainer: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
                      input: (styles) => ({ ...styles, marginTop: 2, marginBottom: 1 }),
                      dropdownIndicator: (styles) => ({ ...styles, padding: 2, color: "#000" }),
                      clearIndicator: (styles) => ({ ...styles, padding: 2 }),
                      menuList: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
                    }}
                  />
                </span>
                <span className='inline-flex flex-col mx-1'>
                  <span>Ticket ID</span>
                  <input
                    disabled={loading}
                    name="id"
                    className={`rounded px-2 bg-grayLight outline-none placeholder-gray py-1 mb-1 ${filters?.id ? "bg-orange" : ""}`}
                    type="text"
                    placeholder="ID"
                    value={filters.id || ""}
                    onChange={(e) => handleFilters('id', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </span>
                <span className='inline-flex flex-col mx-1'>
                  <span>Wristband</span>
                  <input
                    disabled={loading}
                    name="wristbandCode"
                    className={`rounded px-2 bg-grayLight outline-none placeholder-gray py-1 mb-1 ${filters.wristbandCode ? "bg-orange" : ""}`}
                    type="text"
                    placeholder="Wristband Code"
                    value={filters.wristbandCode || ""}
                    onChange={(e) => handleFilters('wristbandCode', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </span>
                <span className='inline-flex flex-col mx-1 md:w-1/5'>
                  <span>Status</span>
                  <select
                    disabled={loading}
                    name="status"
                    type="text"
                    value={filters.exchanged || false}
                    onChange={(e) => handleFilters('status', e.target.value)}
                    className={`bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.exchanged ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="sold">Sold</option>
                    <option className='h-8 text-black' value="refunded">Refunded</option>
                    <option className='h-8 text-black' value="hasBeenUsed">Used</option>
                    <option className='h-8 text-black' value="noShow">Not used</option>
                    <option className='h-8 text-black' value="deleted">Deleted</option>
                  </select>
                </span>
                <span className='inline-flex flex-col mx-1 md:w-1/5'>
                  <span>Exchange State</span>
                  <select
                    disabled={loading}
                    name="exchanged"
                    type="text"
                    value={filters.exchanged || false}
                    onChange={(e) => handleFilters('exchanged', e.target.value)}
                    className={`bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.exchanged ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="exchanged">Exchanged</option>
                    <option className='h-8 text-black' value="not-exchanged">Not Exchanged</option>
                  </select>
                </span>

                {/* <span className='inline-flex flex-col mx-1 md:w-1/5'>
                  <span>With/without account</span>
                  <select
                    disabled={loading}
                    name="account"
                    type="text"
                    value={filters.account || false}
                    onChange={(e) => handleFilters('account', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.account ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="with">Ticket with account</option>
                    <option className='h-8 text-black' value="without">Ticket without account</option>
                  </select>
                </span> */}
              </div>

              <div className='flex flex-col w-full md:block md:border-grayLight md:border-b md:py-2 md:mb-2'>
                <span className='inline-flex flex-col mx-1'>
                  <span>Name</span>
                  <input
                    disabled={loading}
                    name="name"
                    className={`rounded px-2 bg-grayLight outline-none placeholder-gray py-1 mb-1 ${filters.name ? "bg-orange" : ""}`}
                    type="text"
                    placeholder="Name"
                    value={filters.name || ""}
                    onChange={(e) => handleFilters('name', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </span>
                <span className='inline-flex flex-col mx-1'>
                  <span>Email</span>
                  <input
                    disabled={loading}
                    name="email"
                    className={`rounded px-2 bg-grayLight outline-none placeholder-gray py-1 mb-1 ${filters.email ? "bg-orange" : ""}`}
                    type="text"
                    placeholder="Email"
                    value={filters.email || ""}
                    onChange={(e) => handleFilters('email', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </span>
                <span className='inline-flex flex-col mx-1'>
                  <span>Phone</span>
                  <input
                    disabled={loading}
                    name="phone"
                    className={`rounded px-2 bg-grayLight outline-none placeholder-gray py-1 mb-1 ${filters.phone ? "bg-orange" : ""}`}
                    type="text"
                    placeholder="Phone"
                    value={filters.phone || ""}
                    onChange={(e) => handleFilters('phone', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </span>
                {/* <span className='inline-flex flex-col mx-1'>
                  <span>Device ID</span>
                  <input
                    disabled={loading}
                    name="deviceId"
                    className={`rounded px-2 bg-grayLight outline-none placeholder-gray py-1 mb-1 ${filters.deviceId ? "bg-orange" : ""}`}
                    type="text"
                    placeholder="Device ID"
                    value={filters.deviceId || ""}
                    onChange={(e) => handleFilters('deviceId', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </span> */}
              </div>
              <div className='flex flex-col w-full md:block md:border-grayLight md:border-b md:py-2 md:mb-2'>
                <span className='inline-flex flex-col mx-1'>
                  <span>Point of Sale</span>
                  <select
                    disabled={loading}
                    name="pointOfSale"
                    type="text"
                    value={filters.pointOfSale || false}
                    onChange={(e) => handleFilters('pointOfSale', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.pointOfSale ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="guests">Guests Invitations</option>
                    <option className='h-8 text-black' value="Feverup">Fever</option>
                    <option className='h-8 text-black' value="kaboodle">Kaboodle</option>
                    <option className='h-8 text-black' value="Virreina">Virreina</option>
                    <option className='h-8 text-black' value="Redtkt">Redtkt</option>
                    <option className='h-8 text-black' value="api">API</option>
                    <option className='h-8 text-black' value="dice">DICE</option>
                    <option className='h-8 text-black' value="Resident Advisor">Resident Advisor</option>
                    <option className='h-8 text-black' value="Ticketmaster">Ticketmaster</option>
                    <option className='h-8 text-black' value="marketplace">Marketplace</option>
                    <option className='h-8 text-black' value="suppliers">Suppliers</option>
                    <option className='h-8 text-black' value="Festicket">Festicket</option>
                  </select>
                </span>
                {/* <span className='inline-flex flex-col mx-1'>
                  <span>importBatchId</span>
                  <input
                    disabled={loading}
                    name="importBatchId"
                    className={`rounded px-2 bg-grayLight outline-none placeholder-gray py-1 mb-1 ${filters.importBatchId ? "bg-orange" : ""}`}
                    type="text"
                    placeholder="importBatchId"
                    value={filters.importBatchId || ""}
                    onChange={(e) => handleFilters('importBatchId', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </span>
                <span className='inline-flex flex-col mx-1'>
                  <span>importOriginSource</span>
                  <input
                    disabled={loading}
                    name="importOriginSource"
                    className={`rounded px-2 bg-grayLight outline-none placeholder-gray py-1 mb-1 ${filters.importOriginSource ? "bg-orange" : ""}`}
                    type="text"
                    placeholder="importOriginSource"
                    value={filters.importOriginSource || ""}
                    onChange={(e) => handleFilters('importOriginSource', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </span> */}
                <span className='inline-flex flex-col mx-1'>
                  <span>Valid KYC</span>
                  <select
                    disabled={loading}
                    name="validKyc"
                    type="text"
                    value={filters.validKyc || false}
                    onChange={(e) => handleFilters('validKyc', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.validKyc ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="valid">Valid KYC</option>
                    <option className='h-8 text-black' value="invalid">Invalid KYC</option>
                    <option className='h-8 text-black' value="nokyc">NO KYC</option>
                  </select>
                </span>
                <span className='inline-flex flex-col mx-1'>
                  <span>Valid Data</span>
                  <select
                    disabled={loading}
                    name="validData"
                    type="text"
                    value={filters.validData || false}
                    onChange={(e) => handleFilters('validData', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.validData ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="valid">Yes</option>
                    <option className='h-8 text-black' value="invalid">No</option>
                  </select>
                </span>
                <span className='inline-flex flex-col mx-1'>
                  <span>In Ticket Payment</span>
                  <select
                    disabled={loading}
                    name="inTicketPayment"
                    type="text"
                    value={filters.inTicketPayment || false}
                    onChange={(e) => handleFilters('inTicketPayment', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.inTicketPayment ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="paid">Paid</option>
                    <option className='h-8 text-black' value="notPaid">Not Paid</option>
                  </select>
                </span>
                {/* <span className='inline-flex flex-col mx-1'>
                  <span>Search KYC Rejection</span>
                  <input
                    disabled={loading}
                    name="kycRejection"
                    className={`rounded px-2 bg-grayLight outline-none placeholder-gray py-1 mb-1 ${filters.kycRejection ? "bg-orange" : ""}`}
                    type="text"
                    placeholder="Rejection"ww
                    value={filters.kycRejection || ""}
                    onChange={(e) => handleFilters('kycRejection', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                </span> */}
                {/* <span className='inline-flex flex-col mx-1'>
                  <span>Ticket Language</span>
                  <select
                    disabled={loading}
                    name="ticketLanguage"
                    value={filters.ticketLanguage || false}
                    onChange={(e) => handleFilters('ticketLanguage', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.ticketLanguage ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="en">English</option>
                    <option className='h-8 text-black' value="es">Spanish</option>
                    <option className='h-8 text-black' value="ca">Catalan</option>
                  </select>
                </span>
                <span className='inline-flex flex-col mx-1'>
                  <span>User Language</span>
                  <select
                    disabled={loading}
                    name="userLanguage"
                    value={filters.userLanguage || false}
                    onChange={(e) => handleFilters('userLanguage', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.userLanguage ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="en">English</option>
                    <option className='h-8 text-black' value="es">Spanish</option>
                    <option className='h-8 text-black' value="ca">Catalan</option>
                  </select>
                </span> */}
                <span className='inline-flex flex-col mx-1'>
                  <span>Minor/Adult</span>
                  <select
                    disabled={loading}
                    name="age"
                    type="text"
                    value={filters.age || false}
                    onChange={(e) => handleFilters('age', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.age ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="minors">Minors</option>
                    <option className='h-8 text-black' value="adults">Adults</option>
                  </select>
                </span>
                {/* <span className='inline-flex flex-col mx-1'>
                  <span>Has been used</span>
                  <select
                    disabled={loading}
                    name="hasBeenUsed"
                    type="text"
                    value={filters.hasBeenUsed || false}
                    onChange={(e) => handleFilters('hasBeenUsed', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.hasBeenUsed ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="yes">Yes</option>
                    <option className='h-8 text-black' value="no">No</option>
                  </select>
                </span> */}
        
                {/* <span className='inline-flex flex-col mx-1'>
                  <span>Wallet</span>
                  <select
                    disabled={loading}
                    name="wallet"
                    type="text"
                    value={filters.wallet || false}
                    onChange={(e) => handleFilters('wallet', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.wallet ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="drinks">Has drinks</option>
                    <option className='h-8 text-black' value="food">Has food</option>
                  </select>
                </span> */}
                <span className='inline-flex flex-col mx-1'>
                  <span>Marketing Acceptance</span>
                  <select
                    disabled={loading}
                    name="terms"
                    type="text"
                    value={filters.terms || false}
                    onChange={(e) => handleFilters('terms', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.terms ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">All</option>
                    <option className='h-8 text-black' value="terms2">Yes</option>
                  </select>
                </span>
                <span className='inline-flex flex-col mx-1'>
                  <span>Exchanged after</span>
                  <input
                    disabled={loading}
                    name="exchangedAfter"
                    className={`rounded px-2 bg-grayLight outline-none placeholder-gray py-1 mb-1 ${filters.exchangedAfter ? "bg-orange" : ""}`}
                    type="datetime-local"
                    placeholder="exchangedAfter"
                    value={filters.exchangedAfter || ""}
                    onChange={(e) => handleFilters('exchangedAfter', e.target.value)}
                  />
                </span>
                <span className='inline-flex flex-col mx-1'>
                  <span>Exchanged before</span>
                  <input
                    disabled={loading}
                    name="exchangedBefore"
                    className={`rounded px-2 bg-grayLight outline-none placeholder-gray py-1 mb-1 ${filters.exchangedBefore ? "bg-orange" : ""}`}
                    type="datetime-local"
                    placeholder="exchangedBefore"
                    value={filters.exchangedBefore || ""}
                    onChange={(e) => handleFilters('exchangedBefore', e.target.value)}
                  />
                </span>
                {/* <span className='inline-flex flex-col mx-1'>
                  <span>Links</span>
                  <select
                    disabled={loading}
                    name="links"
                    type="text"
                    value={filters.links || false}
                    onChange={(e) => handleFilters('links', e.target.value)}
                    className={` bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 ${filters.links ? "bg-orange" : ""}`}
                  >
                    <option className='h-8 text-black' value="">No filter</option>
                    {data?.links?.map((link, index) => (
                      <option key={index} className='h-8 text-black' value={link._id}>{link.title}</option>
                    ))}
                  </select>
                </span> */}

                {/* <span className='inline-flex flex-col mx-1'>
                  <p className='opacity-0 pointer-events-none'>Show deleted</p>
                  <span className='flex flex-row items-center h-full my-1 '>
                    <input
                      disabled={loading}
                      className={`mr-1 cursor-pointer ${filters.showDeleted ? "accent-orange" : ""}`}
                      type="checkbox"
                      name="showDeleted"
                      id="showDeleted"
                      checked={filters.showDeleted || false}
                      onChange={(e) => handleFilters('showDeleted', !filters.showDeleted)}
                    />
                    <label className='mr-2 cursor-pointer select-none mt-2px' htmlFor="showDeleted">Show deleted</label>
                  </span>
                </span>

                <span className='inline-flex flex-col mx-1'>
                  <p className='opacity-0 pointer-events-none'>Show Refunded</p>
                  <span className='flex flex-row items-center h-full my-1 '>
                    <input
                      disabled={loading}
                      className={`mr-1 cursor-pointer ${filters.showRefunded ? "accent-orange" : ""}`}
                      type="checkbox"
                      name="showRefunded"
                      id="showRefunded"
                      checked={filters.showRefunded || false}
                      onChange={(e) => handleFilters('showRefunded', !filters.showRefunded)}
                    />
                    <label className='mr-2 cursor-pointer select-none mt-2px' htmlFor="showRefunded">Show refunded</label>
                  </span>
                </span> */}
                {/* <span className='inline-flex flex-col mx-1'>
                  <p className='opacity-0 pointer-events-none'>Show Used</p>
                  <span className='flex flex-row items-center h-full my-1 '>
                    <input
                      disabled={loading}
                      className={`mr-1 cursor-pointer ${filters.showHasBeenUsed ? "accent-orange" : ""}`}
                      type="checkbox"
                      name="showHasBeenUsed"
                      id="showHasBeenUsed"
                      checked={filters.showHasBeenUsed || false}
                      onChange={(e) => handleFilters('showHasBeenUsed', !filters.showHasBeenUsed)}
                    />
                    <label className='mr-2 cursor-pointer select-none mt-2px' htmlFor="showHasBeenUsed">Show Used</label>
                  </span>
                </span> */}
              </div>
            </div>

            <div className='flex flex-wrap items-end justify-end my-2 md:my-1'>
              <div>
                <div className="flex justify-end">
                  <Button color='white' visibility={!loading && !updating} onClick={clearFilters} className="mr-2">Clean filters</Button>
                  <Button disable={loading || updating} onClick={filterTickets}>{loading ? 'Loading...' : 'Filter'}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className={`p-2 mb-1 text-xs bg-white rounded ticketFilters ${showColumnsFilters ? '' : 'hidden'}`}>
        <div className="flex flex-col justify-between">
          <span className='mb-2 md:mb-0'>Visible columns</span>
          <form className="flex items-start w-full md:flex-col">
            <span className='inline-flex flex-col mx-1'>
              <div className='flex flex-wrap items-center'>
                {columnsData.length > 0 && columnsData.map(c => (
                  <ColumnFilter key={`columnfilter-${c.key}`} filterKey={c.key} label={c.label}></ColumnFilter>
                ))}
              </div>
            </span>
          </form>
        </div>
      </div>

      <div className={`p-2 mb-1 text-xs bg-white rounded ticketFilters ${showQuery ? '' : 'hidden'}`}>
        <textarea
          disabled
          name="query"
          className={`w-full h-32 px-2 py-1 rounded bg-grayLight outline-none resize-y`}
          placeholder={'Query'}
          value={data && data.query && JSON.stringify(JSON.parse(data.query), undefined, 4) || ''}
        />
      </div>

      <div className='w-full mb-4'>
        <div className='flex flex-wrap'>
          {tagsToFilter && Object.keys(tagsToFilter).map(key => {
            if (!externalFilters.includes(key) && tagsToFilter[key]) {
              if (key === "filterTemplate") return false
              if (key.includes('ticketType')) {
                const ticketTypeTitle = ticketTypes.find(tt => tt.value === tagsToFilter[key])?.label

                return (
                  <span className="relative h-full p-1 my-1 mr-1 text-xs rounded cursor-pointer bg-orange" onClick={() => { handleRemoveFilter(key) }}>ticketType: <strong className="wght-semibold">{ticketTypeTitle}</strong> </span>
                )
              }

              return (
                <span
                  key={`tag-${key}`}
                  className="relative p-1 my-1 mr-1 text-xs rounded cursor-pointer bg-orange"
                  onClick={() => { handleRemoveFilter(key) }}
                >
                  {key}: <strong className="wght-semibold">{tagsToFilter[key].toString()}</strong>
                </span>
              )
            }
          })}
        </div>
      </div>

      <Loader
        loading={loading}
        className="ml-auto"
        text="Loading..."
      >
        <NothingToList
          nothing={!data || !data.tickets || data.tickets.length === 0}
          className="mt-8"
          text="Nothing to list or filters not applied"
        >
          <div className="w-full overflow-scroll">
            {role !== 'read' &&
              <div className='flex justify-between'>
                <div className='flex'>
                  <Button
                    onClick={() => setMultipleSelect(!multipleSelect)}
                    color={`${multipleSelect ? 'green' : 'invertedGreen'}`}
                    size="small" disable={loading || updating}
                  >
                    Send multiple messages
                  </Button>
                  <Button
                    onClick={() => handleSendMessage(batchTickets)}
                    size="small"
                    visibility={!!(multipleSelect && batchTickets.length > 0)}
                  >
                    Send
                  </Button>
                </div>
                <Button onClick={openSendBatchMessagesModal} color="invertedGreen" size="small" disable={loading || updating}>Send message to all filtered tickets with email {countEmail && `(${countEmail})`}</Button>
              </div>
            }

            <table className="w-full mt-2 border-separate">
              <thead className="w-full text-xs text-left bg-white border-b border-grayLight wght-semibold whitespace-nowrap">
                <tr className='sticky top-0 bg-white border-b border-grayLight'>
                  {multipleSelect &&
                    <th className="border-b-2 border-grayLight"></th>
                  }
                  <th className="border-b-2 border-grayLight"></th>
                  {columnsData.length > 0 && columnsData.map(c => (
                    <Column key={`column-${c.key}`} filterKey={c.key} label={c.label}></Column>
                  ))}
                  <th className="sticky right-0 px-4 py-2 bg-white border-b-2 border-l-2 border-grayLight">
                    <span className="relative wght-semibold">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.tickets?.map((info) => (
                  <tr
                    key={info._id}
                    className={`
                      relative items-center text-xs text-left border-b border-grayLight wght-light whitespace-nowrap bg-white
                      ${!info.exchanged ? 'opacity-60' : ''}
                      ${batchTickets.includes(info) ? 'bg-primaryLight' : ''}
                    `}
                  >
                    <th className="w-1 p-0">
                      {info.exchanged && info.isValid.length === 0 &&
                        <div title={'Exchanged'} className={`w-1 h-9 text-xxs bg-green-300 cursor-default`}></div>
                      }
                      {!info.exchanged &&
                        <div title={'Not exchanged'} className={`w-1 h-9 text-xxs bg-gray cursor-default`}></div>
                      }
                      {info.exchanged && info.isValid.length > 0 &&
                        <div title={'Not exchanged'} className={`w-1 h-9 text-xxs bg-warning cursor-default`}></div>
                      }
                    </th>

                    {multipleSelect &&
                      <th className='flex items-center justify-center h-9'>
                        <input
                          disabled={loading} className='w-4 h-full m-auto cursor-pointer' type="checkbox"
                          onChange={() => batchSelector(info)}
                          checked={batchTickets.includes(info)}
                        />
                      </th>
                    }

                    {columnsData.length > 0 && columnsData.map(c => (
                      <Cell
                        key={`cell-${c.key}`}
                        filterKey={c.key}
                        info={info}
                        visibleColumns={visibleColumns}
                        setShowQRTickedId={setShowQRTickedId}
                        handleCopyText={handleCopyText}
                        events={events}
                      />
                    ))}
                    <th className="sticky right-0 px-2 py-1  border-l-2 border-solid bg-white border-grayLight">
                      <div className='flex items-center justify-end'>
                        {info.publicId && typeof qr[modelName] !== 'undefined' &&
                          <span className='inline-block w-5 h-5 mr-1 cursor-pointer' onClick={() => setShowQR(modelName === 'Giftcard' ? info.code : info.publicId)}>
                            <img src="/assets/images/qr.svg" alt="" className="w-5 h-5" />
                          </span>
                        }
                        <Button
                          onClick={() => handleSendMessage([info])}
                          color="invertedGreen"
                          size="small"
                          disable={loading || updating}
                          visibility={!!(role !== 'read')}
                        >Message</Button>
                        <Button to={`/admin/${event}/edit/ticket/${info._id}`}>
                          View
                        </Button>
                      </div>
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </NothingToList>

        {showQR && typeof qr[modelName] !== 'undefined' && <div className="fixed inset-0 z-40 flex items-center justify-center" style={{ backgroundColor: 'rgba(255,255,255,.5)' }} onClick={() => setShowQR(null)}>
          <div className="flex flex-col justify-center p-4 bg-white cursor-pointer" onClick={() => handleCopyText('QR code', `acst://${qr[modelName]}/${showQR}`)}>
            <QRCode size={300} value={`acst://${qr[modelName]}/${showQR}`} />
            <span className="p-2 mt-2 text-xs bg-white">{`acst://${qr[modelName]}/${showQR}`}</span>
          </div>
        </div>}

        {showQRTickedId && typeof qr[modelName] !== 'undefined' && <div className="fixed inset-0 z-40 flex items-center justify-center" style={{ backgroundColor: 'rgba(255,255,255,.5)' }} onClick={() => setShowQRTickedId(null)}>
          <div className="flex flex-col justify-center p-4 bg-white cursor-pointer" onClick={() => handleCopyText('QR code', `${showQRTickedId}`)}>
            <QRCode size={300} value={`${showQRTickedId}`} />
            <span className="p-2 mt-2 text-xs bg-white">{`${showQRTickedId}`}</span>
          </div>
        </div>}

        <Pagination
          page={page}
          setPage={setPage}
          hasMorePages={hasMorePages}
          loading={loading}
        />
      </Loader>
    </>
  )
}
export default Tickets
