import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import { useSession } from '../contexts/AuthContext'
import StatsProgressBar from './StatsProgressBar'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import { getUserPreference, updateCookiePreferences } from "./Lib/utils"
import Button from "./Button"
import Sales from './Form/Sales'
import { TicketsTable } from './WaitlistTable'

const StatsTickets = ({ organizationTitle = '' }) => {
  const history = useHistory()
  const { name: modelName, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const [loading, setLoading] = useState(true)
  const title = 'Waitlist Stats'
  const [data, setData] = useState([])
  const [role, setRole] = useState(false)
  const token = useSession()
  const [showNoStock, setShowNoStock] = useState(false)
  const [autoRefresh, setAutoRefresh] = useState(getUserPreference({
    parentKey: 'statsTickets',
    key: 'autoRefresh',
    defaultValue: false,
  }))

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsTickets',
      key: 'autoRefresh',
      value: autoRefresh,
    })
  }, [autoRefresh])

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        setLoading(true)
      }, 10000)
      return () => clearInterval(interval)
    }
  }, [autoRefresh])

  useEffect(() => {
    getData()
  }, [loading])

  useEffect(() => {
    getData()
  }, [showNoStock, event])

  const getData = () => {
    fetch(`${process.env.REACT_APP_API_URL}model-stats-waitlist`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        organization: getOrganization(),
        event: getEvent(),
        showNoStock,
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        const rawData = response.body
        if (response.status === 200) {
          setRole(rawData.role)
          setData(rawData.statsWaitlist)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          }
          if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>

      {role && (
        <div className="flex">
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className="wght-semibold">{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      )}
      <div className="mt-4">
        {
          data?.length ? (
            <div className="overflow-x-auto">
              <TicketsTable data={data} />
            </div>
          )
          : !loading ? <h2>Please select an Event</h2> : null
        }
      </div>
    </div >
  )
}

export default StatsTickets
