import React, { useState, useEffect } from 'react';

const TagSelector = ({ tags, onSelectionChange }) => {
  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const toggleTag = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
    );
  };

  useEffect(() => {
    onSelectionChange(selectedTags);
  }, [selectedTags, onSelectionChange]);

  return (
    <div>
      <div
        className="px-2 py-1 text-xs bg-white border rounded cursor-pointer text-primary border-primary hover:wght-semibold"
        onClick={() => setOpenTagsModal(!openTagsModal)}
      >
        <span className="px-2">Tags</span>
      </div>
      <div className="relative z-50">
        <div className={`absolute border top-2 left-0 text-xs rounded cursor-pointer text-primary ${openTagsModal ? '' : 'hidden'}`}>
          <span className="flex flex-col flex-wrap p-2 bg-white rounded whitespace-nowrap">
            {tags.map((tag) => (
              <span key={tag}>
                <input
                  className="mr-1 cursor-pointer"
                  type="checkbox"
                  id={`tag-${tag}`}
                  name={`tag-${tag}`}
                  onChange={() => toggleTag(tag)}
                  checked={selectedTags.includes(tag)}
                />
                <label
                  className="mr-4 cursor-pointer select-none md:mr-2"
                  htmlFor={`tag-${tag}`}
                >
                  {tag}
                </label>
              </span>
            ))}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TagSelector;
