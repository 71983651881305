import React from 'react'
import Wildcard from './Wildcard'
import Cookies from 'js-cookie'

const Form = ({
  name,
  data,
  onChange,
  showErrors,
  mode,
  innerForm = false,
  parentIsSorting = false,
  currentData = false,
  readMode = false,
  timezone,
}) => {
  const typesWithModelsInfo = ['models', 'model', 'rules', 'ticketpermissions']
  // Get timezone from another field (tz or timezone) and avoid default select value -1. If not, get from props (parent field), or get from selected event (by cookie) or set it by default.
  const timezoneForFields = data.stored?.tz !== -1 && data.stored?.tz || data.stored?.timezone !== -1 && data.stored?.timezone
    || timezone
    || Cookies.get('eventTimezone')
    || 'Europe/Madrid'

  const renderedKeys = new Set(); // To track rendered items


  return (
    <div className={`${innerForm ? '' : 'p-4 bg-white'}`}>
      <div className="flex flex-wrap">
        {typeof data.models[name] !== 'undefined' &&
          data.models[name].map((item, index) => {
            // First check dependencies for all items (grouped or not)
            if (item.options?.dependsOn) {
              const dependencyValue = data.stored[item.options.dependsOn];
              if (dependencyValue !== 1) {
                renderedKeys.add(item.name); // Mark as rendered to skip later
                // resetValue(item)
                // return null;
              }
            }

            // Handle grouped items
            if (item.options?.groupBy && !renderedKeys.has(item.name)) {
              // Collect items to be grouped
              const groupedItems = [item];
              let nextIndex = index + 1;
              
              while (nextIndex < data.models[name].length && 
                     data.models[name][nextIndex].options?.groupBy === item.options.groupBy) {
                // Check dependencies for grouped items
                const nextItem = data.models[name][nextIndex];
                if (!nextItem.options?.dependsOn || 
                    data.stored[nextItem.options.dependsOn] === 1) {
                  groupedItems.push(nextItem);
                }
                nextIndex++;
              }

              // Mark grouped items as rendered
              groupedItems.forEach(groupedItem => renderedKeys.add(groupedItem.name));

              // Only render group if there are visible items
              if (groupedItems.length > 0) {
                return (
                  <div key={item.options.groupBy} className="flex flex-wrap w-full">
                    {groupedItems.map((groupedItem, groupedIndex) => (
                      <Wildcard
                        mode={mode}
                        onChange={onChange}
                        showErrors={showErrors}
                        storedData={data.stored}
                        languages={data.languages}
                        find={data.find}
                        files={data.files}
                        key={groupedItem.name + groupedIndex}
                        {...groupedItem}
                        models={
                          typesWithModelsInfo.includes(groupedItem.type) ? data.models : null
                        }
                        currentData={
                          groupedItem.type === 'ticketpermissions' ? currentData : null
                        }
                        innerForm={innerForm}
                        parentIsSorting={parentIsSorting}
                        readMode={readMode}
                        timezone={timezoneForFields}
                      />
                    ))}
                  </div>
                );
              }
            }

            // Handle individual items
            if (!renderedKeys.has(item.name)) {
              renderedKeys.add(item.name);
              return (
                <Wildcard
                  mode={mode}
                  onChange={onChange}
                  showErrors={showErrors}
                  storedData={data.stored}
                  languages={data.languages}
                  find={data.find}
                  files={data.files}
                  key={item.name + index}
                  {...item}
                  models={
                    typesWithModelsInfo.includes(item.type) ? data.models : null
                  }
                  currentData={
                    item.type === 'ticketpermissions' ? currentData : null
                  }
                  innerForm={innerForm}
                  parentIsSorting={parentIsSorting}
                  readMode={readMode}
                  timezone={timezoneForFields}
                />
              );
            }
            return null;
          })}
      </div>
    </div>
  );
}

export default Form
