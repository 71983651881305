import React from 'react';
import { Ticket, TicketCheck, Clock, Users, Package } from 'lucide-react';

const MetricItem = ({ title, value, icon }) => (
  <div className="flex flex-col items-start border-gray-200 px-4">
    <div className="text-sm text-gray-600 mb-1">{title}</div>
    <div className="flex items-center justify-start gap-2">
      {icon}
      <span className="ml-2 text-xl font-bold">{value}</span>
    </div>
  </div>
);

const Sales = ({ storedData }) => {
  const {
    marketplaceStockSold = 0,
    userStockSold = 0,
    userStock = 0,
    waitingListUsers = 0,
    waitingListTickets = 0,
    userStockOnHold = 0,
  } = storedData;
  
  return (
    <div className="w-full bg-white rounded-lg shadow px-6 py-4">
      <div className="flex flex-row justify-between items-center">
        <MetricItem 
          title="Marketplace Sales" 
          value={marketplaceStockSold} 
          icon={<TicketCheck className="w-5 h-5" />} 
        />
        <MetricItem 
          title="WL: Sales" 
          value={userStockSold} 
          icon={<TicketCheck className="w-5 h-5" />} 
        />
        <MetricItem 
          title="WL: Stock" 
          value={userStock} 
          icon={<Ticket className="w-5 h-5" />} 
        />
        <MetricItem 
          title="WL: Reserved" 
          value={userStockOnHold} 
          icon={<TicketCheck className="w-5 h-5" />} 
        />
        <MetricItem 
          title="WL: Users" 
          value={waitingListUsers} 
          icon={<Users className="w-5 h-5" />} 
        />
        <MetricItem 
          title="WL: Tickets" 
          value={waitingListTickets} 
          icon={<Ticket className="w-5 h-5" />} 
        />
      </div>
    </div>
  );
};

export default Sales;
